<template>
  <div class="hello">
    <div class="main">
      <div style="background: #ffffff;display: flex;justify-content: space-between;align-items: center;">
        <img class="logo-img" src="../img/macaujc.png" alt="">

        <div
            style="display: flex; align-items: flex-start; font-size: 23px; flex-direction: column;  font-weight: 800;    width: 48%;    color: #e70707;">
        </div>

        <span class="switch_style" @click="switchOpenCodeStyle">切換開獎風格</span>
      </div>
      <Carousel/>


      <div v-if="game_show[1] === '1'">
        <div class="title-info">
          <div style="font-size: 18px;color: #000000;font-weight: 700">澳門六合彩22:30</div>

          <div style="font-size: 12px;color: #d80011;">
              <span>開獎时间 <span style="color: #000000">第 <p style="color: #d80011;display: inline">{{ expect_macaoDh }}</p>期</span><br>{{ openTime_macaoDh }} &nbsp;&nbsp;&nbsp;&nbsp; {{
                  getWeekDay(openTime_macaoDh)
                }}</span>
          </div>
        </div>
        <div class="countdown-zone">
          <div style="display: flex;align-items: center;">
            <div class="countdown" id="countdown2">
              <span class="countdown-number">{{ countdownMac[0] }}</span>
              <span class="countdown-number">{{ countdownMac[1] }}</span>
              <span
                  style="font-size: 22px;font-weight: 700;font-family: Microsoft JhengHei,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Arial,sans-serif;">:</span>
              <span class="countdown-number" style="margin-left: 0;">{{ countdownMac[2] }}</span>
              <span class="countdown-number">{{ countdownMac[3] }}</span>
              <span
                  style="font-size: 22px;font-weight: 700;font-family: Microsoft JhengHei,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Arial,sans-serif;">:</span>
              <span class="countdown-number" style="margin-left: 0;">{{ countdownMac[4] }}</span>
              <span class="countdown-number">{{ countdownMac[5] }}</span>
            </div>
            <div style="display:flex;align-items:center;margin-top: 3px;">
              <a href="//macaujc.com" target="_blank">
                <div class="find">官方核對</div>
              </a>
              <router-link to="/macaujc">
                <div style="display: inline-block;float: right;">
                  <div style="color: #000000;font-size: 13px;display: inline-block;">開獎歷史</div>
                  <i class="layui-icon" style="font-size: 13px; color: #da0f1f;">&gt;</i>
                </div>
              </router-link>
            </div>
            <span class="huifang2" @click="reviewOpenCode_mac">重播</span>
          </div>
          <div  ref="blockElement" class="openCodeResult">
            <div class="history_jieguo" v-for="(value, index) in currentOpenCode_macaoDh.slice(0, 6)" :key="index">
              <div v-if="code_style!='0'" class="block-lump"
                   :style='"background:" + return_wave(value)+ ";width:"+code_width + "px;height:" + code_height+"px;font-size:" + code_size + "px;"'>
                {{ value }}
              </div>
              <div v-if="code_style=='0'" class="block-lump-circle"
                   :style='"background: " + code_img[return_wave(value)] + ";background-size: 100% 100%;"+ ";width:"+code_width + "px;height:" + code_width+"px;font-size:" + code_size_cir + "px;line-height:" + code_width+"px"'>
                {{ value }}
              </div>

              <div class="zodiac">{{ returnShuXing(value, openTime_macaoDh) }}</div>
            </div>
            <div class="history_add-logo" v-show="currentOpenCode_macaoDh.length > 0">
              <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 12px; color: #da0f1f;"></i>
            </div>
            <div class="history_jieguo" v-for="(value, index) in currentOpenCode_macaoDh.slice(6,7)" :key="index">
              <div v-if="code_style!='0'" class="block-lump"
                   :style='"background:" + return_wave(value)+ ";width:"+code_width + "px;height:" + code_height+"px;font-size:" + code_size + "px;"'>
                {{ value }}
              </div>
              <div v-if="code_style=='0'" class="block-lump-circle"
                   :style='"background: " + code_img[return_wave(value)] + ";background-size: 100% 100%;" + ";width:"+code_width + "px;height:" + code_width+"px;font-size:" + code_size_cir + "px;line-height:" + code_width+"px"'>
                {{ value }}
              </div>
              <div class="zodiac">{{ returnShuXing(value, openTime_macaoDh) }}</div>
            </div>
          </div>

        </div>
      </div>

      <div v-if="game_show[2] === '1'">
        <div class="title-info">
          <div style="font-size: 18px;color: #0928c6;font-weight: 700;margin-left: 3px">新澳門六合彩</div>
          <div style="font-size: 12px;color: #d80011;">
              <span>開獎时间 <span style="color: #000000">第 <p style="color: #d80011;display: inline">{{ expect_new_mac }}</p>期</span><br>{{ openTime_new_mac }} &nbsp;&nbsp;&nbsp;&nbsp;  {{
                  getWeekDay(openTime_new_mac)
                }}</span>
          </div>
        </div>
        <div class="countdown-zone">
          <div style="display: flex;align-items: center;">
            <div class="countdown" id="countdown3">
              <span class="countdown-number">{{ countdownMacNew[0] }}</span>
              <span class="countdown-number">{{ countdownMacNew[1] }}</span>
              <span
                  style="font-size: 22px;font-weight: 700;font-family: Microsoft JhengHei,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Arial,sans-serif;">:</span>
              <span class="countdown-number" style="margin-left: 0;">{{ countdownMacNew[2] }}</span>
              <span class="countdown-number">{{ countdownMacNew[3] }}</span>
              <span
                  style="font-size: 22px;font-weight: 700;font-family: Microsoft JhengHei,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Arial,sans-serif;">:</span>
              <span class="countdown-number" style="margin-left: 0;">{{ countdownMacNew[4] }}</span>
              <span class="countdown-number">{{ countdownMacNew[5] }}</span>
            </div>
            <div style="display:flex;align-items:center;margin-top: 3px;">
              <a href="//macaujc.com" target="_blank">
                <div class="find">官方核對</div>
              </a>
              <router-link to="/macaujc2">
                <div style="display: inline-block;float: right;">
                  <div style="color: #000000;font-size: 13px;display: inline-block;">開獎歷史</div>
                  <i class="layui-icon" style="font-size: 13px; color: #da0f1f;">&gt;</i>
                </div>
              </router-link>

            </div>
            <span class="huifang2" @click="reviewOpenCode_mac_new">重播</span>
          </div>
          <div ref="blockElement" class="openCodeResult">
            <div class="history_jieguo" v-for="(value, index) in currentOpenCode_new_mac.slice(0, 6)" :key="index">
              <div v-if="code_style!='0'" class="block-lump"
                   :style='"background:" + return_wave(value)+ ";width:"+code_width + "px;height:" + code_height+"px;font-size:" + code_size + "px;"'>
                {{ format_number(value) }}
              </div>
              <div v-if="code_style=='0'" class="block-lump-circle"
                   :style='"background: " + code_img[return_wave(value)] + ";background-size: 100% 100%;" + ";width:"+code_width + "px;height:" + code_width+"px;font-size:" + code_size_cir + "px;line-height:" + code_width+"px"'>
                {{ format_number(value) }}
              </div>
              <div class="zodiac">{{ returnShuXing(value, openTime_new_mac) }}</div>
            </div>
            <div class="history_add-logo" v-show="currentOpenCode_new_mac.length > 0">
              <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 12px; color: #da0f1f;"></i>
            </div>
            <div class="history_jieguo" v-for="(value, index) in currentOpenCode_new_mac.slice(6,7)" :key="index">
              <div v-if="code_style!='0'" class="block-lump"
                   :style='"background:" + return_wave(value)+ ";width:"+code_width + "px;height:" + code_height+"px;font-size:" + code_size + "px;"'>
                {{ format_number(value) }}
              </div>
              <div v-if="code_style=='0'" class="block-lump-circle"
                   :style='"background: " + code_img[return_wave(value)] + ";background-size: 100% 100%;" + ";width:"+code_width + "px;height:" + code_width+"px;font-size:" + code_size_cir + "px;line-height:" + code_width+"px"'>
                {{ format_number(value) }}
              </div>
              <div class="zodiac">{{ returnShuXing(value, openTime_new_mac) }}</div>
            </div>
          </div>
        </div>
      </div>


      <div class="fenlei" style="background: #FFFFFF">

        <div v-if="game_show[1] === '1'"
             style="display: flex;align-items: center;background-color: #0068b7;color: #FFFFFF;width:  100%;justify-content: center;"
             @click="switch_history(1)">
          <span :class="history_show_1=== 1? 'bg_yellow font-l': 'font-l'" style="font-weight: 700;">澳門六合彩</span>
        </div>
        <div v-if="game_show[2] === '1'"
             style="display: flex;align-items: center;background-color: rgb(38 39 38);color: #FFFFFF;width:  100%;justify-content: center;"
             @click="switch_history(2)">
          <span :class="history_show_2=== 1? 'bg_yellow font-l': 'font-l'" style="font-weight: 500;">新澳門六合彩</span>
        </div>
      </div>

      <div v-if="history_show_1===1 && history_2.length>0" class="history_open">
        <div class="history_list" v-for="(item, ind) in history_2" style="background:#FFFFFF" :key="ind">
          <div class="fenceng">
            <div class="left_info">期号</div>
            <div class="right_info" style="color: #7e7c7c">第{{ item.expect }}期<div class="btn_video" @click="play_video(item)">直播</div></div>
          </div>
          <div class="fenceng">
            <div class="left_info">開獎時間</div>
            <div class="right_info" style="color: #7e7c7c">{{ item.openTime }}</div>
          </div>
          <div class="fenceng">
            <div class="left_info">
              中獎號碼
            </div>
            <div class="right_info" v-if="code_style!='0'">
              <div style="overflow: hidden">
                <div class="history_jieguo" v-for="(value, ind) in item.openCode.split(',').slice(0, 6)" :key="ind">
                  <div class="history_block-lump" :style='"background:" + item.wave.split(",")[ind]'>{{
                      format_number(value)
                    }}
                  </div>
                  <div class="history_zodiac">{{ item.zodiac.split(",")[ind] }}</div>
                </div>
                <div class="add-logo">
                  <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 12px; color: #da0f1f;"></i>
                </div>

                <div class="history_jieguo" v-for="(value, ind) in item.openCode.split(',').slice(6, 7)" :key="ind">
                  <div class="history_block-lump" :style='"background:" + item.wave.split(",")[6]'>{{
                      format_number(value)
                    }}
                  </div>
                  <div class="history_zodiac">{{ item.zodiac.split(",")[6] }}</div>
                </div>
              </div>
            </div>
            <div class="right_info" v-if="code_style=='0'">
              <div style="overflow: hidden">
                <div class="history_jieguo" v-for="(value, ind) in item.openCode.split(',').slice(0, 6)" :key="ind">
                  <div class="history_block-lump-circle" :style='"background:" + code_img[item.wave.split(",")[ind]]'>
                    {{ format_number(value) }}
                  </div>
                  <div class="history_zodiac">{{ item.zodiac.split(",")[ind] }}</div>
                </div>
                <div class="add-logo">
                  <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 8px; color: #da0f1f;"></i>
                </div>
                <div class="history_jieguo" v-for="(value, ind) in item.openCode.split(',').slice(6, 7)" :key="ind">
                  <div class="history_block-lump-circle" :style='"background:" + code_img[item.wave.split(",")[6]]'>
                    {{ format_number(value) }}
                  </div>
                  <div class="history_zodiac">{{ item.zodiac.split(",")[6] }}</div>
                </div>
              </div>
            </div>
          </div>



        </div>

        <div class="load-more" >
          <a style="color: red;" href="/macaujc" >點擊加载更多>>></a>
        </div>
      </div>

      <div v-if="history_show_2===1 && history_3.length>0" class="history_open">
        <div class="history_list" v-for="(item, ind) in history_3" style="background:#FFFFFF" :key="ind">
          <div class="fenceng">
            <div class="left_info">期号</div>
            <div class="right_info" style="color: #7e7c7c">第{{ item.expect }}期<div class="btn_video" @click="play_video(item)">直播</div></div>
          </div>
          <div class="fenceng">
            <div class="left_info">開獎時間</div>
            <div class="right_info" style="color: #7e7c7c">{{ item.openTime }}</div>
          </div>
          <div class="fenceng">
            <div class="left_info">
              中獎號碼
            </div>
            <div class="right_info" v-if="code_style!='0'">
              <div style="overflow: hidden">
                <div class="history_jieguo" v-for="(value, ind) in item.openCode.split(',').slice(0, 6)" :key="ind">
                  <div class="history_block-lump" :style='"background:" + item.wave.split(",")[ind]'>{{
                      format_number(value)
                    }}
                  </div>
                  <div class="history_zodiac">{{ item.zodiac.split(",")[ind] }}</div>
                </div>
                <div class="add-logo">
                  <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 12px; color: #da0f1f;"></i>
                </div>

                <div class="history_jieguo" v-for="(value, ind) in item.openCode.split(',').slice(6, 7)" :key="ind">
                  <div class="history_block-lump" :style='"background:" + item.wave.split(",")[6]'>{{
                      format_number(value)
                    }}
                  </div>
                  <div class="history_zodiac">{{ item.zodiac.split(",")[6] }}</div>
                </div>
              </div>
            </div>
            <div class="right_info" v-if="code_style=='0'">
              <div style="overflow: hidden">
                <div class="history_jieguo" v-for="(value, ind) in item.openCode.split(',').slice(0, 6)" :key="ind">
                  <div class="history_block-lump-circle" :style='"background:" + code_img[item.wave.split(",")[ind]]'>
                    {{ format_number(value) }}
                  </div>
                  <div class="history_zodiac">{{ item.zodiac.split(",")[ind] }}</div>
                </div>
                <div class="add-logo">
                  <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 8px; color: #da0f1f;"></i>
                </div>

                <div class="history_jieguo" v-for="(value, ind) in item.openCode.split(',').slice(6, 7)" :key="ind">
                  <div class="history_block-lump-circle" :style='"background:" + code_img[item.wave.split(",")[6]]'>
                    {{ format_number(value) }}
                  </div>
                  <div class="history_zodiac">{{ item.zodiac.split(",")[6] }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="load-more" @click="load_more_3">
          <a style="color: red;" href="/macaujc2" >點擊加载更多>>></a>
        </div>
      </div>
    </div>


    <div class="bottom_navi">
      <router-link to="/" style="display: contents;">
        <div class="bottom_navi_xuan" style="background: red;">
          <i class="iconfont icon-shouye" style="font-size: 25px"></i>
          <div>首頁</div>
        </div>
      </router-link>

      <router-link to="/macaujc" style="display: contents;">
        <div class="bottom_navi_xuan">
          <i class="iconfont icon-trend" style="font-size: 25px"></i>
          <div>開獎历史</div>
        </div>
      </router-link>
      <router-link to="/open_video" style="display: contents;">
        <div class="bottom_navi_xuan">
          <i class="iconfont  icon-bofang" style="font-size: 25px"></i>
          <div>開獎直播</div>
        </div>
      </router-link>
      <router-link to="/api" style="display: contents;">
        <div class="bottom_navi_xuan">
          <i class="iconfont  icon-shujujiekou" style="font-size: 25px"></i>
          <div>開獎调用</div>

        </div>
      </router-link>
      <router-link to="/news" style="display: contents;">
        <div class="bottom_navi_xuan">
          <i class="iconfont  icon-Rrl_s_152" style="font-size: 25px"></i>
          <div>官方公告</div>

        </div>
      </router-link>
    </div>

    <div id="video" v-if="video_cover === 1" style="    width: 92%;">
      <div>
        <div style="display: flex;align-items: center;justify-content: flex-start;font-size: 12px">
          <div style="margin-left: 6px;">
            <span class="name_lo">{{ video_info.title }}</span> <span
              style="font-size: 12px;">第 <span style="color: #d21e1e;">{{ video_info.expect }}</span> 期</span>
            <span class="huifang">回放</span>
            <br>
            <span>開獎時間：</span> <span>{{ video_info.openTime }}</span>
          </div>
          <div v-if="code_style!=='0' && video_info.openTime"
               style="overflow: hidden;display: flex;justify-content: center;;-moz-transform: scale(0.45);zoom: 0.57;margin-right: 30px;">
            <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(0, 6)" :key="ind">
              <div class="history_block-lump" :style='"background:" + video_info.wave.split(",")[ind]'>
                {{ format_number(value) }}
              </div>
              <div class="history_zodiac">{{ video_info.zodiac.split(",")[ind] }}</div>
            </div>
            <div class="add-logo">
              <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 8px; color: #da0f1f;"></i>
            </div>
            <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(6, 7)" :key="ind">
              <div class="history_block-lump" :style='"background:" + video_info.wave.split(",")[6]'>
                {{ format_number(value) }}
              </div>
              <div class="history_zodiac">{{ video_info.zodiac.split(",")[6] }}</div>
            </div>
          </div>

          <div v-if="code_style==='circle' && video_info.openTime"
               style="overflow: hidden;display: flex;justify-content: center;zoom: 0.57;align-items: center;margin-right: 30px;">
            <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(0, 6)" :key="ind">
              <div class="history_block-lump-circle" :style='"background:" + code_img[video_info.wave.split(",")[ind]]'>
                {{ format_number(value) }}
              </div>
              <div class="history_zodiac">{{ video_info.zodiac.split(",")[ind] }}</div>
            </div>
            <div class="add-logo">
              <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 8px; color: #da0f1f;"></i>
            </div>
            <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(6, 7)" :key="ind">
              <div class="history_block-lump-circle" :style='"background:" + code_img[video_info.wave.split(",")[6]]'>
                {{ format_number(value) }}
              </div>
              <div class="history_zodiac">{{ video_info.zodiac.split(",")[6] }}</div>
            </div>
          </div>


          <img @click="close_video" src="../img/close.png" alt="" style="width: 30px;position: absolute;right: -9px;top: -7px;">
        </div>
      </div>
      <video :src="'https://macaujc.com/' + video_info.video_type + '/'+video_info.expect+'.mp4'" autoplay="autoplay" style="width: 100%" class="backV" id="play_video"></video>
    </div>

  </div>
</template>
<script src="../js/base.js"></script>

<style scoped src="../css/base.css"></style>
<style src="../font/iconfont.css"></style>
