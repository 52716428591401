import { createApp } from 'vue'
import App from './App.vue'
import {createRouter, createWebHistory} from 'vue-router';



import Faq from './components/FaqInfo.vue';
import ContactPC from './components/ContactPc.vue';
import HsPc from './components/HistoryPc.vue';
import HsMacPc from './components/HistoryMacNewPc.vue';
// import History from './components/HisHk.vue';
import Index from './components/IndexBase.vue';
import HistoryMac from './components/HisMac.vue';
import HistoryMacNew from './components/HisMacNew.vue';
import OpenVideo from './components/OpenVideo.vue';
import OpenVideoPc from './components/OpenVideoPc.vue';
import OpenVideo2Pc from './components/OpenVideo2Pc.vue';
import OpenVideo2 from './components/OpenVideo2.vue';
import ApiInfo from './components/ApiInfo.vue';
import ApiPcInfo from './components/ApiPcInfo.vue';
import Notice from './components/NoticeInfo.vue';
import BasePc from './components/BasePc.vue';
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

let code_style= localStorage.getItem("code_style_square")
if (code_style === null) {
    window.localStorage.setItem("code_style_square", "1")
}
let live_line= localStorage.getItem("live_line")
let live_line_old= localStorage.getItem("live_line_old")
if (live_line === null) {
    window.localStorage.setItem("live_line", "1")
}
if (live_line_old === null) {
    window.localStorage.setItem("live_line_old", "1")
}
const routes = [

    { path: '/news', component: Notice,meta:{title:"澳門六合彩公告",} },
    { path: '/contact', component: isMobile ? Index: ContactPC ,meta:{title:"澳門六合彩 聯繫我們",} },
    { path: '/faq', component: Faq  ,meta:{title:"澳門六合彩Faq",description:""}},
    { path: '/api', component: isMobile ? ApiInfo: ApiPcInfo ,meta:{title:"澳門六合彩開獎接口",description:""}},
    { path: '/macaujc/', component: isMobile ? HistoryMac : HsPc ,meta:{title:"澳門六合彩開獎歷史",description:""}},
    { path: '/macaujc2/', component: isMobile ? HistoryMacNew: HsMacPc ,meta:{title:"新澳門六合彩開獎歷史",description:""} },
    // { path: '/hkjc/', component: History  ,meta:{title:"香港六合彩開獎歷史",description:""}},
    { path: '/open_video2/', component: isMobile?OpenVideo2:OpenVideo2Pc  ,meta:{title:"新澳門六合彩開獎直播",description:""}},
    { path: '/open_video/', component: isMobile?OpenVideo:OpenVideoPc,meta:{title:"澳門六合彩開獎直播",description:""} },
    { path: '/', component: isMobile ?Index: BasePc,meta:{title:"澳門六合彩官方網站",description:""} },
    {path: '/:catchAll(.*)', redirect: '/',}

];

const router = createRouter({
    // mode: "history",
    history: createWebHistory(),
    routes
});


router.beforeEach((to, from, next) => {
    document.title = to.meta.title || '澳門六合彩官方網站';
    // document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description || '');
    next();

});





// createApp(App).use(router).mount('#app')
const app = createApp(App);

// 在开发模式下禁用 Vue 警告的打印
if (process.env.NODE_ENV === 'development') {
    app.config.warnHandler = () => {};
}

app.use(router).mount('#app');