

<template>
  <div>
    <div class="main" style="    background: #ffffff;">
      <div style="background: #ffffff">
        <img class="logo-img" src="../img/macaujc.png" alt="">
      </div>
      <div class="fenlei">
        <div >
          <router-link to="/open_video">
            <span class="font-l">澳門六合彩</span>
          </router-link>
        </div>
        <div style="background-color: #17c000;">
          <router-link to="/open_video2">
            <span   class=" color_f">新澳門六合彩</span>
          </router-link>
        </div>
<!--        <div-->
<!--            style="display: flex;align-items: center;background-color: #004dc0;color: #FFFFFF;width: 100%;height: 30px;justify-content: center;">-->
<!--          <a href="/open_video3">-->
<!--            <span style=" font-weight: 700" class="font-l">澳門十點半</span>-->
<!--          </a>-->
<!--        </div>-->
      </div>
      <LiveStream2/>


      <div class="countdown-zone">

        <div style="border-bottom: 1px solid #dddddd;margin: 12px auto;"></div>
        <div ref="blockElement" style="overflow: hidden;margin-left: 1%;" v-if="history.length>0">
          <div style="font-size: 19px;width: 96%;margin: 0px auto;">第 <p style="color: #d80011;display: inline">
            {{ deal_expect(next_expect, history[0].openTime) }}</p>期開獎號碼
          </div>
          <div style="    display: flex;">
            <div class="jieguo" v-for="(value, ind) in currentOpenCode.slice(0, 6)" :key="ind">
              <div v-if="code_style!='0'" class="block-lump" :style='"background:" + return_wave(value)+ ";width:"+code_width + "px;height:" + code_height+"px;font-size:" + code_size + "px;"'>
                {{ format_number(value) }}
              </div>
              <div v-if="code_style=='0'" class="block-lump-circle"
                   :style='"background: " + code_img[return_wave(value)] + ";background-size: 100% 100%;"'>
                {{ format_number(value) }}
              </div>
              <div class="zodiac">{{ returnShuXing(value, this_info.openTime) }}</div>
            </div>
            <div class="add-logo">
              <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 12px; color: #da0f1f;"></i>
            </div>
            <div class="jieguo" v-for="(value, ind) in currentOpenCode.slice(6,7)" :key="ind">
              <div v-if="code_style!='0'" class="block-lump" :style='"background:" + return_wave(value) + ";width:"+code_width + "px;height:" + code_height+"px;font-size:" + code_size + "px;"'>
                {{ format_number(value) }}
              </div>
              <div v-if="code_style=='0'" class="block-lump-circle"
                   :style='"background: " + code_img[return_wave(value)] + ";background-size: 100% 100%;"'>
                {{ format_number(value) }}
              </div>
              <div class="zodiac">{{ returnShuXing(value, this_info.openTime) }}</div>
            </div>
          </div>
        </div>
        <div style="border-bottom: 1px solid #dddddd;margin: 12px auto;"></div>
        <div style="font-size: 19px;width: 96%;margin: 0px auto 0px 5%;">下期截止時間 <p
            style="color: #d80011;display: inline">{{ openTime }}</p></div>

        <div class="countdown" id="countdown">
          <span class="countdown-number">{{ countdown[0] }}</span>
          <span class="countdown-number">{{ countdown[1] }}</span>
          <span
              style="font-size: 19px;font-weight: 700;font-family: Microsoft JhengHei,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Arial,sans-serif;">:</span>
          <span class="countdown-number" style="margin-left: 0;">{{ countdown[2] }}</span>
          <span class="countdown-number">{{ countdown[3] }}</span>
          <span
              style="font-size: 19px;font-weight: 700;font-family: Microsoft JhengHei,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Arial,sans-serif;">:</span>
          <span class="countdown-number" style="margin-left: 0;">{{ countdown[4] }}</span>
          <span class="countdown-number">{{ countdown[5] }}</span>
        </div>
      </div>

      <div style="display: flex;width: 100%;flex-wrap: wrap;justify-content: space-around;">
        <div v-for="(lottery, index) in history" :key="index" class="video_cover_par">
          <div class="video_cover">
            <img src="../img/play.png" alt="" class="play" expect="{{ lottery.expect }}" id="{{ lottery.id }}"
                 @click="play_video(lottery)">
          </div>
          <div class="video_expect_number"><span>新澳門六合彩 第 </span><span class="seconde_span">{{
              lottery.expect
            }}</span> <span> 期 開獎視頻</span></div>

        </div>
      </div>
    </div>
    <div class="bottom_navi">
      <router-link to="/" style="display: contents;">
        <div class="bottom_navi_xuan">
          <i class="iconfont icon-shouye" style="font-size: 25px"></i>
          <div>首頁</div>
        </div>
      </router-link>
      <router-link to="/macaujc" style="display: contents;">
        <div class="bottom_navi_xuan">
          <i class="iconfont icon-trend" style="font-size: 25px"></i>
          <div>開獎历史</div>
        </div>
      </router-link>
      <router-link to="/open_video" style="display: contents;">
        <div class="bottom_navi_xuan" style="background: red;">
          <i class="iconfont  icon-bofang" style="font-size: 25px"></i>
          <div>開獎直播</div>
        </div>
      </router-link>
      <router-link to="/api" style="display: contents;">
        <div class="bottom_navi_xuan">
          <i class="iconfont  icon-shujujiekou" style="font-size: 25px"></i>
          <div>開獎调用</div>

        </div>
      </router-link>
      <router-link to="/news" style="display: contents;">
        <div class="bottom_navi_xuan">
          <i class="iconfont  icon-Rrl_s_152" style="font-size: 25px"></i>
          <div>官方公告</div>

        </div>
      </router-link>

    </div>


    <div id="video" v-if="video_cover === 1" style="    width: 92%;">
      <div>
        <div style="display: flex;align-items: center;justify-content: flex-start;font-size: 12px">
          <div style="margin-left: 6px;">
            <span class="name_lo">新澳門六合彩</span> <span
              style="font-size: 12px;">第 <span style="color: #d21e1e;">{{ video_info.expect }}</span> 期</span>
            <span class="huifang">回放</span>
            <br>
            <span>開獎時間：</span> <span>{{ video_info.openTime }}</span>
          </div>
          <div v-if="code_style!='0' && video_info.openTime"
               style="overflow: hidden;display: flex;justify-content: center;;-moz-transform: scale(0.45);zoom: 0.57;margin-right: 30px;">
            <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(0, 6)" :key="ind">
              <div class="history_block-lump" :style='"background:" + video_info.wave.split(",")[ind]'>
                {{ format_number(value) }}
              </div>
              <div class="history_zodiac">{{ video_info.zodiac.split(",")[ind] }}</div>
            </div>
            <div class="add-logo">
              <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 8px; color: #da0f1f;"></i>
            </div>
            <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(6, 7)" :key="ind">
              <div class="history_block-lump" :style='"background:" + video_info.wave.split(",")[6]'>
                {{ format_number(value) }}
              </div>
              <div class="history_zodiac">{{ video_info.zodiac.split(",")[6] }}</div>
            </div>
          </div>

          <div v-if="code_style=='0' && video_info.openTime"
               style="overflow: hidden;display: flex;justify-content: center;zoom: 0.57;align-items: center;margin-right: 30px;">
            <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(0, 6)" :key="ind">
              <div class="history_block-lump-circle" :style='"background:" + code_img[video_info.wave.split(",")[ind]]'>
                {{ format_number(value) }}
              </div>
              <div class="history_zodiac">{{ video_info.zodiac.split(",")[ind] }}</div>
            </div>
            <div class="add-logo">
              <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 8px; color: #da0f1f;"></i>
            </div>
            <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(6, 7)" :key="ind">
              <div class="history_block-lump-circle" :style='"background:" + code_img[video_info.wave.split(",")[6]]'>
                {{ format_number(value) }}
              </div>
              <div class="history_zodiac">{{ video_info.zodiac.split(",")[6] }}</div>
            </div>
          </div>


          <img @click="close_video" src="../img/close.png" alt="" style="width: 30px;position: absolute;right: -9px;top: -7px;">
        </div>
      </div>
      <video :src="'https://macaujc.com/video2/'+video_info.expect+'.mp4'" autoplay="autoplay" style="" class="backV" id="play_video"></video>
    </div>
  </div>
</template>

<style scoped src="../css/base.css"></style>
<style scoped src="../css/video_pc.css"></style>
<script src="../js/video2.js"></script>