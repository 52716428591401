<script setup>

</script>

<template>
  <div class="main" style="    background: #ffffff;">
    <div style="background: #ffffff">
      <img class="logo-img" style="border: none;width: 173px;height: 96px;margin-top: 20px;margin-bottom: 10px;" src="../img/macaujc.png" alt="">
    </div>

    <div class="daohang">
      <router-link to="/">
        <div style="background: #D90011;" class="daohangdiv">首頁</div>
      </router-link>
      <router-link to="/macaujc">
        <div style="background: #00A0EA;" class="daohangdiv">開獎公告</div>
      </router-link>
      <router-link to="/open_video">
        <div style="background: #17C001;" class="daohangdiv">開獎視頻</div>
      </router-link>
      <router-link to="/faq">
        <div style="background: #D9A400;" class="daohangdiv dhBg">FAQ</div>
      </router-link>
      <router-link to="/api">
        <div style="background: #692CC5;" class="daohangdiv">開獎調用</div>
      </router-link>
      <router-link to="/contact">
        <div style="background: #C42CB5;" class="daohangdiv">聯係我們</div>
      </router-link>
    </div>
    <div style="width: 100%;height: 5px;background: #eee222;margin-bottom: 11px;"></div>

    <div class="fenlei">
      <router-link to="/faq">
        <div class="history_qiehuan  history_radius">
          <span class="font-l">FAQ</span>
        </div>
      </router-link>
    </div>

    <div class="mainText">
      <div class="title">
        澳門六合彩FAQ
      </div>
      <div class="main">
        <div>
          1、macaujc是什么意思
        </div>
        <p>
          答：macau則是澳門。jc則是Jockey Club縮寫所以含義是澳門賽馬會。
        </p>
        <div>
          2、澳門六合彩（macaujc）開獎數據是否公正權威?
        </div>
        <p>
          答：所有開獎數據都由macaujc全球首發比任何一家機構網站都提前1分鐘發布.並且域名是澳門六合彩的純英文com 例:www.macaumarksix.com完全免費
        </p>
        <div>
          3、澳門六合彩（macaujc）網站是否支持投註？
        </div>
        <p>
          答：NO，不管在任何國家地區我們都是不支持投註的 也沒有任何的網絡平臺 我們只是開獎公正處 由於法律原因我們並沒有在任何地區任何國家開放投註。
        </p>
        <div>
          4、澳門六合彩（macaujc）是否有分站或者其他域名？
        </div>
        <p>
          答：NO，我們官方網站的域名macaujc.com因市場上有www-macaujc.com等等假冒域名以及網站頁面進行欺诈。請廣大用戶註意官方域名為macaujc.com開獎接口開放macaumarksixcom無其他域名。
        </p>

        <div>
          6、問題聯系我們
        </div>
        <p>
          E-mail：service@macaujc.com
        </p>

      </div>
    </div>


    <footer> 版權所有 不得轉載 @澳門六合彩 (macaujc.com)</footer>

  </div>
</template>

<style src="../css/faq.css" scoped></style>
<style src="../css/base_pc.css" scoped></style>