<template>
  <div class="announcement-container">
    <!-- 遮罩层 -->
    <div class="mask" v-if="show" @click="toggleMask"></div>

    <!-- 公告内容 -->
    <div class="announcement" v-if="show">
      <h1>澳門六合彩官方通知</h1>
      <p>
        澳門六合彩因市場熱門程度下滑，分公司決定從2024年2月1日起開獎時間調整為22:30分開獎。請勿相信市面上的資料網，圖庫開獎結果可能會出現篡改。為了保證開獎結果公平公正開獎結果請以macaujc.com（澳門賽馬會）澳門六合彩官方網站為準。
      </p>
      <button @click="toggleMask" class="close_notice">关闭</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexNotice',
  props: {
    title: String,
    message: String
  },
  data() {
    return {
      show: true,
      counter: 300,
      timer: null,
    };
  },
  methods: {
    toggleMask() {
      this.show = !this.show;
    },
    startTimer() {
      this.timer = setInterval(() => {
        if (this.counter > 0) {
          this.counter -= 1;
        } else {
          this.toggleMask()
          this.clearTimer();
        }
      }, 1000);
    },
    clearTimer() {
      // 清除计时器
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
  },
  created() {
  this.startTimer()
  }
}
</script>

<style scoped>
.announcement-container {
  position: relative;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明 */
  z-index: 1000; /* 确保遮罩层在最上面 */
}

.announcement {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 20px 0 20px;;
  margin: 20px 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  z-index: 1001;
  width: 80vw;
  text-align: justify;
  letter-spacing: 1px;
}

.announcement h1 {
  margin-top: 0;
  color: #e30404;
  font-weight: 800;
  text-align: center;
}
.announcement p {
  font-size: 20px;
}
.close_notice{
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  outline: none;
  font-size: 16px;
  border-radius: 10px;
}
</style>
