<script>
  export default {
    name: "ApiInfo",
  data() {
    return {
      macaujc: 1,
      macaujc2: 0,
      macaujc3: 0,
    }

  },

  methods: {
    switch_api: function (index) {
      // $(".font-l").css("color", "#ffffff").eq(index).css("color", "rgb(255, 241, 0)").css("font-weight","700")
      if (index === 0) {
        this.macaujc = 1
        this.macaujc2 = 0
        this.macaujc3 = 0
      } else if (index === 1) {
        this.macaujc = 0
        this.macaujc2 = 1
        this.macaujc3 = 0
      } else if (index === 2) {
        this.macaujc = 0
        this.macaujc2 = 0
        this.macaujc3 = 1
      }
    }
  }
}
</script>

<template>
  <div>
    <div style="    background: #ffffff;">
      <div style="background: #ffffff">
        <img class="logo-img" src="../img/macaujc.png" alt="">
      </div>
      <!--    <div class="touma">開獎调用</div>-->
      <div class="fenlei">


        <div
            style="display: flex;align-items: center;background-color: #0068b7;color: #FFFFFF;width: 100%;justify-content: center;"
            @click="switch_api(0)">
          <span :style="macaujc === 1?'font-size: 17px;color: rgb(255,241,0); ':'font-size: 17px;'" class="font-l">澳門六合彩接口</span>

        </div>
        <div
            style="display: flex;align-items: center;background-color: #17c000;color: #FFFFFF;width: 100%;justify-content: center;"
            @click="switch_api(1)">
          <span :style="macaujc2 === 1?'font-size: 17px;color: rgb(255,241,0); ':'font-size: 17px;'" class="font-l">新澳門六合彩接口</span>

        </div>
      </div>

      <div v-if="macaujc==1" lay-skin="notepad"
           style="font-family: auto;font-size: 13px;background: #ffffff;">

   <div  style="font-weight: 800;"><span>最新一期開獎接口:(平台盤口專用）：</span><a target="_blank" href="https://api.macaumarksix.com/api/macaujc.com"
                                                        style="color: #dd0c0c; display: inline-block">https://api.macaumarksix.com/api/macaujc.com</a>
        </div>
        <div  style="font-weight: 800;"><span>一顆一顆開獎接口（資料網專用）：</span><a target="_blank" href="https://api.macaumarksix.com/api/live"
                                                          style="color: #dd0c0c; display: inline-block">https://api.macaumarksix.com/api/live</a>
        </div>
        <div  style="font-weight: 800;"><span>歷史開獎接口：</span><a target="_blank" href="https://api.macaumarksix.com/history/macaujc/y/2024"
                                          style="color: #dd0c0c; display: inline-block">https://api.macaumarksix.com/history/macaujc/y/2024</a>
        </div>
        <div  style="font-weight: 800;"><span>按期號查詢：</span><a target="_blank" href="https://api.macaumarksix.com/history/macaujc/expect/2024001"
                                        style="color: #dd0c0c; display: inline-block">https://api.macaumarksix.com/history/macaujc/expect/2024001</a>
        </div>
        <div  style="font-weight: 800;"><span>目前開放的澳門六合彩純英文域名+路徑即可使用 為了防禦更大的DDOS攻擊</span><br>
        api.macaumarksix.com<br>
        api-macaujc.com</div>

        <div style="font-weight: 800;"><span>直播調用接口：</span><br><span style="color: #dd0c0c">https://live-macaujc.com/live/livestream/macaujc2230.flv</span></div>
        <div style="font-weight: 800;"><span>直播調用接口：</span> <br><span style="color: #dd0c0c">https://live-macaujc.com/live/livestream/macaujc2230.m3u8</span></div>
<pre style="text-align: left;" class="layui-code" lay-skin="notepad" lay-encode="true">

{
  "result": true,
  "message": "操作成功！",
  "code": 200,
  "data": [
    {
      "expect": "2024031",
      "openCode": "01,32,40,08,05,46,22",
      "zodiac": "兔,猴,鼠,猴,豬,馬,馬",
      "openTime": "2024-01-31 21:32:32",
      "wave": "red,green,red,red,green,red,green",
      "info": "數據來源macaujc.com 接口故障問題協助電報TG:macaumarksix"
    }
  ]
}
</pre>



  </div>


      <div v-if="macaujc2==1" lay-skin="notepad"
           style="font-family: auto;font-size: 13px;background: #ffffff;">

        <div  style="font-weight: 800;"><span>最新一期開獎接口:(平台盤口專用）：</span><a target="_blank" href="https://api.macaumarksix.com/api/macaujc2.com"
                                                                      style="color: #dd0c0c; display: inline-block">https://api.macaumarksix.com/api/macaujc2.com</a>
        </div>
        <div  style="font-weight: 800;"><span>一顆一顆開獎接口（資料網專用）：</span><a target="_blank" href="https://api.macaumarksix.com/api/live2"
                                                                   style="color: #dd0c0c; display: inline-block">https://api.macaumarksix.com/api/live2</a>
        </div>
        <div  style="font-weight: 800;"><span>歷史開獎接口：</span><a target="_blank" href="https://api.macaumarksix.com/history/macaujc2/y/2024"
                                                   style="color: #dd0c0c; display: inline-block">https://api.macaumarksix.com/history/macaujc2/y/2024</a>
        </div>
        <div  style="font-weight: 800;"><span>按期號查詢：</span><a target="_blank" href="https://api.macaumarksix.com/history/macaujc2/expect/2024001"
                                                 style="color: #dd0c0c; display: inline-block">https://api.macaumarksix.com/history/macaujc2/expect/2024001</a>
        </div>
        <div  style="font-weight: 800;"><span>目前開放的澳門六合彩純英文域名+路徑即可使用 為了防禦更大的DDOS攻擊</span><br>
          api.macaumarksix.com<br>
          api-macaujc.com</div>

        <div style="font-weight: 800;"><span>直播調用接口：</span><br><span style="color: #dd0c0c">https://live-macaujc.com/live/livestream/new.flv</span></div>
        <div style="font-weight: 800;"><span>直播調用接口：</span><br><span style="color: #dd0c0c">https://live-macaujc.com/live/livestream/new.m3u8</span></div>
<pre style="text-align: left;" class="layui-code" lay-skin="notepad" lay-encode="true">

{
  "result": true,
  "message": "操作成功！",
  "code": 200,
  "data": [
    {
      "expect": "2024031",
      "openCode": "01,32,40,08,05,46,22",
      "zodiac": "兔,猴,鼠,猴,豬,馬,馬",
      "openTime": "2024-01-31 21:32:32",
      "wave": "red,green,red,red,green,red,green",
      "info": "數據來源macaujc.com 接口故障問題協助電報TG:macaumarksix"
    }
  ]
}
</pre>



  </div>



    </div>
    <div class="bottom_navi">
      <router-link to="/" style="display: contents;">
        <div class="bottom_navi_xuan">
          <i class="iconfont icon-shouye" style="font-size: 25px"></i>
          <div>首頁</div>
        </div>
      </router-link>
      <router-link to="/macaujc" style="display: contents;">
        <div class="bottom_navi_xuan">
          <i class="iconfont icon-trend" style="font-size: 25px"></i>
          <div>開獎历史</div>
        </div>
      </router-link>
      <router-link to="/open_video" style="display: contents;">
        <div class="bottom_navi_xuan">
          <i class="iconfont  icon-bofang" style="font-size: 25px"></i>
          <div>開獎直播</div>
        </div>
      </router-link>
      <router-link to="/api" style="display: contents;">
        <div class="bottom_navi_xuan" style="background: red;">
          <i class="iconfont  icon-shujujiekou" style="font-size: 25px"></i>
          <div>開獎调用</div>

        </div>
      </router-link>
      <router-link to="/news" style="display: contents;">
        <div class="bottom_navi_xuan">
          <i class="iconfont  icon-Rrl_s_152" style="font-size: 25px"></i>
          <div>官方公告</div>

        </div>
      </router-link>

    </div>
  </div>
</template>


<style  scoped src="../css/base.css">
</style>
<style scoped>
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.layui-code {
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: #3F3F3F;
  color: #c2be9e;
}
</style>