<template>
  <div>
    <div class="main">
      <div style="background: #ffffff">
        <img class="logo-img" src="../img/macaujc.png" alt="">
      </div>

      <div class="fenlei">

<!--        <div v-if="game_show[0] === '1'"-->
<!--             style="display: flex;align-items: center;background: red;color: #fff100;width: 100%;height: 29px;justify-content: center;">-->
<!--          <router-link to="/hkjc">-->
<!--            <span class="font-l">香港六合彩</span>-->
<!--          </router-link>-->
<!--        </div>-->
        <div v-if="game_show[1] === '1'"
             style="display: flex;align-items: center;background-color: #0068b7;color: #FFFFFF;width: 100%;height: 35px;justify-content: center;">
          <router-link to="/macaujc">
            <span class="font-l" style="color: #fff100;font-weight: 700">澳門六合彩</span>
          </router-link>
        </div>
        <div v-if="game_show[2] === '1'"
             style="display: flex;align-items: center;background-color: #17c000;color: #FFFFFF;width: 100%;height: 35px;justify-content: center;">
          <router-link to="/macaujc2">
            <span class="font-l">新澳門六合彩</span>
          </router-link>
        </div>
        <!--        <div v-if="game_show[3] === '1'"-->
        <!--             style="display: flex;align-items: center;background-color: #244fe7;color: #FFFFFF;width: 100%;height: 35px;justify-content: center;">-->
        <!--          <router-link to="/macaujc3">-->
        <!--            <span class="font-l">澳門十點半</span>-->
        <!--          </router-link>-->
        <!--        </div>-->
      </div>


      <div class="countdown-zone">
        <div class="find-div">
          <div style="font-size: 12px; width: 40%;color: #ffffff;">
            澳門六合彩
          </div>
          <div class="find-input">
            <input class="expect_value" type="number" v-model="query_value" min="0" placeholder="期号">
            <img src="../img/查询.png" class="query_expect" alt="" @click="get_query_expect">
          </div>
        </div>
        <div style="border-bottom: 1px solid #dddddd;margin: 12px auto;"></div>
        <div  ref="blockElement" style="overflow: hidden;" v-if="currentOpenCode">
          <div style="font-size: 17px;width: 96%;margin: 0px auto;display: flex;align-items: center;">第 <p
              style="color: #d80011;display: inline;">{{ deal_expect(next_expect, this_info.openTime) }}</p>期開獎號碼
            <span class="huifang2" @click="getInTimeOpenCode_review">重播</span>
          </div>


          <div class="jieguo" v-for="(value, ind) in currentOpenCode.slice(0, 6)" :key="ind">
            <div v-if="code_style!='0'" class="block-lump" :style='"background:" + return_wave(value)+ ";width:"+code_width + "px;height:" + code_height+"px;font-size:" + code_size + "px;"'>
              {{ format_number(value) }}
            </div>
            <div v-if="code_style=='0'" class="block-lump-circle"
                 :style='"background: " + code_img[return_wave(value)] + ";background-size: 100% 100%;"'>
              {{ format_number(value) }}
            </div>
            <div class="zodiac">{{ returnShuXing(value, this_info.openTime) }}</div>
          </div>
          <div class="add-logo">
            <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 12px; color: #da0f1f;"></i>
          </div>
          <div class="jieguo" v-for="(value, ind) in currentOpenCode.slice(6,7)" :key="ind">
            <div v-if="code_style!='0'" class="block-lump" :style='"background:" + return_wave(value)+ ";width:"+code_width + "px;height:" + code_height+"px;font-size:" + code_size + "px;"'>
              {{ format_number(value) }}
            </div>
            <div v-if="code_style=='0'" class="block-lump-circle"
                 :style='"background: " + code_img[return_wave(value)] + ";background-size: 100% 100%;"'>
              {{ format_number(value) }}
            </div>
            <div class="zodiac">{{ returnShuXing(value, this_info.openTime) }}</div>
          </div>
        </div>
        <div style="border-bottom: 1px solid #dddddd;margin: 12px auto;"></div>
        <div style="font-size: 17px;width: 96%;margin: 0px auto 0px 5%;">下期截止時間 <p
            style="color: #d80011;display: inline">{{ openTime }}</p></div>

        <div class="countdown" id="countdown">
          <span class="countdown-number">{{ countdown[0] }}</span>
          <span class="countdown-number">{{ countdown[1] }}</span>
          <span
              style="font-size: 17px;font-weight: 700;font-family: Microsoft JhengHei,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Arial,sans-serif;">:</span>
          <span class="countdown-number" style="margin-left: 0;">{{ countdown[2] }}</span>
          <span class="countdown-number">{{ countdown[3] }}</span>
          <span
              style="font-size: 17px;font-weight: 700;font-family: Microsoft JhengHei,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Arial,sans-serif;">:</span>
          <span class="countdown-number" style="margin-left: 0;">{{ countdown[4] }}</span>
          <span class="countdown-number">{{ countdown[5] }}</span>
        </div>
      </div>


      <div class="beijing_xuhua">
        <div class="position_sett">
          <div class="condition" style="color: #e21111;background: #fff;">位置</div>
          <div class="condition" :class="set_b(0)" @click="transparent_code(0)">平一</div>
          <div class="condition" :class="set_b(1)" @click="transparent_code(1)">平二</div>
          <div class="condition" :class="set_b(2)" @click="transparent_code(2)">平三</div>
          <div class="condition" :class="set_b(3)" @click="transparent_code(3)">平四</div>
          <div class="condition" :class="set_b(4)" @click="transparent_code(4)">平五</div>
          <div class="condition" :class="set_b(5)" @click="transparent_code(5)">平六</div>
          <div class="condition" :class="set_b(6)" @click="transparent_code(6)">特碼</div>
          <div  @click="transparent_code(7)" class="huanyuan">還原</div>
<!--          <i v-show="trend_show === 0" @click="set_trend_show(1)" class="iconfont icon-tabxiala" style="font-size: 17px;color: rgb(218, 15, 31);line-height: 21px;margin-left: 3px;"></i>-->

        </div>

        <div class="position_sett" >
          <div class="condition" style="color: #e21111;background: #fff;">条件</div>
          <div style="display: flex;">
            <div class="condition" style="background: #f0f0f0">&nbsp;</div>
            <div class="condition" :class="set_b_zo('鼠')" @click="transparent_zodiac('鼠')">鼠</div>
            <div class="condition" :class="set_b_zo('牛')" @click="transparent_zodiac('牛')">牛</div>
            <div class="condition" :class="set_b_zo('虎')" @click="transparent_zodiac('虎')">虎</div>
            <div class="condition" :class="set_b_zo('兔')" @click="transparent_zodiac('兔')">兔</div>
            <div class="condition" :class="set_b_zo('龍')" @click="transparent_zodiac('龍')">龍</div>
            <div class="condition" :class="set_b_zo('蛇')" @click="transparent_zodiac('蛇')">蛇</div>
          </div>
          <div style="display: flex;">
            <div class="condition" style="background: #f0f0f0">&nbsp;</div>
            <div class="condition" style="background: #f0f0f0">&nbsp;</div>
            <div class="condition" :class="set_b_zo('馬')" @click="transparent_zodiac('馬')">馬</div>
            <div class="condition" :class="set_b_zo('羊')" @click="transparent_zodiac('羊')">羊</div>
            <div class="condition" :class="set_b_zo('猴')" @click="transparent_zodiac('猴')">猴</div>
            <div class="condition" :class="set_b_zo('雞')" @click="transparent_zodiac('雞')">雞</div>
            <div class="condition" :class="set_b_zo('狗')" @click="transparent_zodiac('狗')">狗</div>
            <div class="condition" :class="set_b_zo('豬')" @click="transparent_zodiac('豬')">豬</div>
          </div>
          <div  @click="transparent_zodiac(12)" class="huanyuan">還原</div>
<!--          <i @click="set_trend_show(0)" class="iconfont icon-tabshouqi" style="font-size: 17px;color: rgb(218, 15, 31);line-height: 21px;margin-left: 3px;"></i>-->

        </div>
      </div>


      <div class="history_open">
        <div style="display: flex; margin-top: 15px; margin-bottom: 15px; margin-left: 5%;">
          <div :class="{ 'last_year': true, 'this_year': this_year === 2024 }" @click="choose_history(2024)">2024年</div>
          <div :class="{ 'last_year': true, 'this_year': this_year === 2023 }" @click="choose_history(2023)">2023年</div>
          <div :class="{ 'last_year': true, 'this_year': this_year === 2022 }" @click="choose_history(2022)">2022年</div>
          <div :class="{ 'last_year': true, 'this_year': this_year === 2021 }" @click="choose_history(2021)">2021年</div>
        </div>
        <div class="history_list" v-for="(item, index) in history" style="background:#FFFFFF" :key="index" >


          <div class="fenceng">
            <div class="left_info">期号</div>
            <div class="right_info" style="color: #7e7c7c">第{{ deal_expect(item.expect, item.openTime) }}期<div class="btn_video" @click="play_video(item)">直播</div></div>

          </div>
          <div class="fenceng">
            <div class="left_info">開獎時間</div>
            <div class="right_info" style="color: #7e7c7c">{{ item.openTime }}</div>
          </div>
          <div class="fenceng">
            <div class="left_info">
              中獎號碼
            </div>
            <div class="right_info" v-if="code_style!='0'">
              <div style="overflow: hidden">
                <div class="history_jieguo" :class="transparent_code_list[ind] === 0 && transparent_zodiac_list.includes(item.zodiac.split(',')[ind]) ? '':'opacityOne'"  v-for="(value, ind) in item.openCode.split(',').slice(0, 6)" :key="ind">
                  <div class="history_block-lump" :style='"background:" + return_wave(value)'>{{
                      format_number(value)
                    }}
                  </div>
                  <div class="history_zodiac">{{ item.zodiac.split(",")[ind] }}</div>
                </div>
                <div class="add-logo">
                  <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 12px; color: #da0f1f;"></i>
                </div>

                <div class="history_jieguo" :class="transparent_code_list[6] === 0 && transparent_zodiac_list.includes(item.zodiac.split(',')[6]) ? '':'opacityOne'"  v-for="(value, ind) in item.openCode.split(',').slice(6, 7)" :key="ind">
                  <div class="history_block-lump" :style='"background:" + item.wave.split(",")[6]'>
                    {{ format_number(value) }}
                  </div>
                  <div class="history_zodiac">{{ item.zodiac.split(",")[6] }}</div>
                </div>
              </div>
            </div>
            <div class="right_info" v-if="code_style=='0'">
              <div style="overflow: hidden">
                <div class="history_jieguo" :class="transparent_code_list[ind] === 0 && transparent_zodiac_list.includes(item.zodiac.split(',')[ind]) ? '':'opacityOne'"  v-for="(value, ind) in item.openCode.split(',').slice(0, 6)" :key="ind">
                  <div class="history_block-lump-circle" :style='"background:" + code_img[item.wave.split(",")[ind]]'>
                    {{ format_number(value) }}
                  </div>
                  <div class="history_zodiac">{{ item.zodiac.split(",")[ind] }}</div>
                </div>
                <div class="add-logo">

                  <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 8px; color: #da0f1f;"></i>
                </div>

                <div class="history_jieguo" :class="transparent_code_list[6] === 0 && transparent_zodiac_list.includes(item.zodiac.split(',')[6]) ? '':'opacityOne'"  v-for="(value, ind) in item.openCode.split(',').slice(6, 7)" :key="ind">
                  <div class="history_block-lump-circle" :style='"background:" + code_img[item.wave.split(",")[6]]'>
                    {{ format_number(value) }}
                  </div>
                  <div class="history_zodiac">{{ item.zodiac.split(",")[6] }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="bottom_navi">
      <router-link to="/" style="display: contents;">
        <div class="bottom_navi_xuan">
          <i class="iconfont icon-shouye" style="font-size: 25px"></i>
          <div>首頁</div>
        </div>
      </router-link>
      <router-link to="/macaujc" style="display: contents;">
        <div class="bottom_navi_xuan" style="background: red;">
          <i class="iconfont icon-trend" style="font-size: 25px"></i>
          <div>開獎历史</div>
        </div>
      </router-link>
      <router-link to="/open_video" style="display: contents;">
        <div class="bottom_navi_xuan">
          <i class="iconfont  icon-bofang" style="font-size: 25px"></i>
          <div>開獎直播</div>
        </div>
      </router-link>
      <router-link to="/api" style="display: contents;">
        <div class="bottom_navi_xuan">
          <i class="iconfont  icon-shujujiekou" style="font-size: 25px"></i>
          <div>開獎调用</div>
        </div>
      </router-link>
      <router-link to="/news" style="display: contents;">
        <div class="bottom_navi_xuan">
          <i class="iconfont  icon-Rrl_s_152" style="font-size: 25px"></i>
          <div>官方公告</div>
        </div>
      </router-link>

    </div>


    <div id="video" v-if="video_cover === 1" style="    width: 92%;">
      <div>
        <div style="display: flex;align-items: center;justify-content: flex-start;font-size: 12px">
          <div style="">
            <span class="name_lo">澳門六合彩</span> <span
              style="font-size: 12px;">第 <span style="color: #d21e1e;">{{ video_info.expect }}</span> 期</span>
            <span class="huifang">回放</span>
            <br>
            <span>開獎時間：</span> <span>{{ video_info.openTime }}</span>
          </div>
          <div v-if="code_style!='0' && video_info.openTime"
               style="overflow: hidden;display: flex;justify-content: center;;-moz-transform: scale(0.45);zoom: 0.57;">
            <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(0, 6)" :key="ind">
              <div class="history_block-lump" :style='"background:" + video_info.wave.split(",")[ind]'>
                {{ format_number(value) }}
              </div>
              <div class="history_zodiac">{{ video_info.zodiac.split(",")[ind] }}</div>
            </div>
            <div class="add-logo">
              <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 8px; color: #da0f1f;"></i>
            </div>
            <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(6, 7)" :key="ind">
              <div class="history_block-lump" :style='"background:" + video_info.wave.split(",")[6]'>
                {{ format_number(value) }}
              </div>
              <div class="history_zodiac">{{ video_info.zodiac.split(",")[6] }}</div>
            </div>
          </div>

          <div v-if="code_style=='0' && video_info.openTime"
               style="overflow: hidden;display: flex;justify-content: center;zoom: 0.57;align-items: center;margin-right: 30px;">
            <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(0, 6)" :key="ind">
              <div class="history_block-lump-circle" :style='"background:" + code_img[video_info.wave.split(",")[ind]]'>
                {{ format_number(value) }}
              </div>
              <div class="history_zodiac">{{ video_info.zodiac.split(",")[ind] }}</div>
            </div>
            <div class="add-logo">
              <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 8px; color: #da0f1f;"></i>
            </div>
            <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(6, 7)" :key="ind">
              <div class="history_block-lump-circle" :style='"background:" + code_img[video_info.wave.split(",")[6]]'>
                {{ format_number(value) }}
              </div>
              <div class="history_zodiac">{{ video_info.zodiac.split(",")[6] }}</div>
            </div>
          </div>


          <img @click="close_video" src="../img/close.png" alt="" style="width: 30px;position: absolute;right: -9px;top: -7px;">
        </div>
      </div>
      <video :src="'https://macaujc.com/video/'+video_info.expect+'.mp4'" autoplay="autoplay" style="width: 100%" class="backV" id="play_video"></video>
    </div>
  </div>
</template>

<style scoped src="../css/history.css"></style>
<script src="../js/history_mac.js"></script>