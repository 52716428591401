<script>
export default {
  name: "ApiPcInfo",
  data() {
    return {
      macaujc: 1,
      macaujc2: 0,
      macaujc3: 0,
    }

  },

  methods: {
    switch_api: function (index) {
      // $(".font-l").css("color", "#ffffff").eq(index).css("color", "rgb(255, 241, 0)").css("font-weight","700")
      if (index === 0) {
        this.macaujc = 1
        this.macaujc2 = 0
        this.macaujc3 = 0
      } else if (index === 1) {
        this.macaujc = 0
        this.macaujc2 = 1
        this.macaujc3 = 0
      } else if (index === 2) {
        this.macaujc = 0
        this.macaujc2 = 0
        this.macaujc3 = 1
      }
    }
  }
}
</script>

<template>
  <div id="app" class="main" style="    background: #ffffff;">
    <div style="background: #ffffff">
      <img class="logo-img" style="border: none;width: 173px;height: 96px;margin-top: 20px;margin-bottom: 10px;"
           src="../img/macaujc.png" alt="">
    </div>

    <div class="daohang">
      <router-link to="/">
        <div style="background: #D90011;" class="daohangdiv">首頁</div>
      </router-link>
      <router-link to="/macaujc">
        <div style="background: #00A0EA;" class="daohangdiv">開獎公告</div>
      </router-link>
      <router-link to="/open_video">
        <div style="background: #17C001;" class="daohangdiv">開獎視頻</div>
      </router-link>
      <router-link to="/faq">
        <div style="background: #D9A400;" class="daohangdiv">FAQ</div>
      </router-link>
      <router-link to="/api">
        <div style="background: #692CC5;" class="daohangdiv dhBg">開獎調用</div>
      </router-link>
      <router-link to="/contact">
        <div style="background: #C42CB5;" class="daohangdiv">聯係我們</div>
      </router-link>
    </div>
    <div style="width: 100%;height: 5px;background: #eee222;margin-bottom: 11px;"></div>

    <div class="fenlei">
      <div :class="macaujc === 1? 'history_qiehuan  history_radius':'history_qiehuan'" @click="switch_api(0)">
        <span class="font-l">澳門六合彩</span>
      </div>
      <div  :class="macaujc2 === 1? 'history_qiehuan  history_radius':'history_qiehuan'" @click="switch_api(1)">
        <span class="font-l">新澳門六合彩</span>
      </div>

    </div>
    <div v-show="macaujc==1">
      <div lay-skin="notepad"
           style="    font-family: Microsoft JhengHei,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Arial,sans-serif;;font-size: 25px;background: #ffffff;">

        <div style="font-weight: 800;"><span>最新一期開獎接口:(平台盤口專用）：</span><a target="_blank" href="https://api.macaumarksix.com/api/macaujc.com"
                                                             style="color: #dd0c0c; display: inline-block">https://api.macaumarksix.com/api/macaujc.com</a>
        </div>
        <div style="font-weight: 800;"><span>一顆一顆開獎接口（資料網專用）：</span><a target="_blank" href="https://api.macaumarksix.com/api/live"
                                                          style="color: #dd0c0c; display: inline-block">https://api.macaumarksix.com/api/live</a>
        </div>
        <div style="font-weight: 800;"><span>歷史開獎接口：</span><a target="_blank" href="https://api.macaumarksix.com/history/macaujc/y/2024"
                                          style="color: #dd0c0c; display: inline-block">https://api.macaumarksix.com/history/macaujc/y/2024</a>
        </div>
        <div style="font-weight: 800;"><span>按期號查詢：</span><a target="_blank" href="https://api.macaumarksix.com/history/macaujc/expect/2024001"
                                        style="color: #dd0c0c; display: inline-block">https://api.macaumarksix.com/history/macaujc/expect/2024001</a>
        </div>

        <div style="font-weight: 800;"><span>目前開放的澳門六合彩純英文域名+路徑即可使用 為了防禦更大的DDOS攻擊</span></div>
        <div style="font-weight: 800;"><span>api.macaumarksix.com</span></div>
<!--        <div style="font-weight: 800;"><span>www.macaumarksix.com</span></div>-->
        <div style="font-weight: 800;"><span>api-macaujc.com</span></div>

        <div style="font-weight: 800;"><span>直播調用接口：</span><span style="color: #dd0c0c">https://live-macaujc.com/live/livestream/macaujc2230.flv</span></div>
        <div style="font-weight: 800;"><span>直播調用接口：</span><span style="color: #dd0c0c">https://live-macaujc.com/live/livestream/macaujc2230.m3u8</span></div>
        <pre style="text-align: left;" class="layui-code" lay-skin="notepad" lay-encode="true">

{
  "result": true,
  "message": "操作成功！",
  "code": 200,
  "data": [
    {
      "expect": "2024031",
      "openCode": "01,32,40,08,05,46,22",
      "zodiac": "兔,猴,鼠,猴,豬,馬,馬",
      "openTime": "2024-01-31 21:32:32",
      "wave": "red,green,red,red,green,red,green",
      "info": "數據來源macaujc.com 接口故障問題協助電報TG:macaumarksix"
    }
  ]
}
</pre>


      </div>
    </div>

    <div v-show="macaujc2==1">
      <div lay-skin="notepad"
           style="    font-family: Microsoft JhengHei,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Arial,sans-serif;;font-size: 25px;background: #ffffff;">

        <div style="font-weight: 800;"><span>最新一期開獎接口:(平台盤口專用）：</span><a target="_blank" href="https://api.macaumarksix.com/api/macaujc2.com"
                                                             style="color: #dd0c0c; display: inline-block">https://api.macaumarksix.com/api/macaujc2.com</a>
        </div>
        <div style="font-weight: 800;"><span>一顆一顆開獎接口（資料網專用）：</span><a target="_blank" href="https://api.macaumarksix.com/api/live2"
                                                          style="color: #dd0c0c; display: inline-block">https://api.macaumarksix.com/api/live2</a>
        </div>
        <div style="font-weight: 800;"><span>歷史開獎接口：</span><a target="_blank" href="https://api.macaumarksix.com/history/macaujc2/y/2024"
                                          style="color: #dd0c0c; display: inline-block">https://api.macaumarksix.com/history/macaujc2/y/2024</a>
        </div>
        <div style="font-weight: 800;"><span>按期號查詢：</span><a target="_blank" href="https://api.macaumarksix.com/history/macaujc2/expect/2024001"
                                        style="color: #dd0c0c; display: inline-block">https://api.macaumarksix.com/history/macaujc2/expect/2024001</a>
        </div>

        <div style="font-weight: 800;"><span>目前開放的澳門六合彩純英文域名+路徑即可使用 為了防禦更大的DDOS攻擊</span></div>
        <div style="font-weight: 800;"><span>api.macaumarksix.com</span></div>
<!--        <div style="font-weight: 800;"><span>www.macaumarksix.com</span></div>-->
        <div style="font-weight: 800;"><span>api-macaujc.com</span></div>


        <div style="font-weight: 800;"><span>直播調用接口：</span><span style="color: #dd0c0c">https://live-macaujc.com/live/livestream/new.flv</span></div>
        <div style="font-weight: 800;"><span>直播調用接口：</span><span style="color: #dd0c0c">https://live-macaujc.com/live/livestream/new.m3u8</span></div>

        <pre style="text-align: left;" class="layui-code" lay-skin="notepad" lay-encode="true">

{
  "result": true,
  "message": "操作成功！",
  "code": 200,
  "data": [
    {
      "expect": "2024031",
      "openCode": "01,32,40,08,05,46,22",
      "zodiac": "兔,猴,鼠,猴,豬,馬,馬",
      "openTime": "2024-01-31 21:32:32",
      "wave": "red,green,red,red,green,red,green",
      "info": "數據來源macaujc.com 接口故障問題協助電報TG:macaumarksix"
    }
  ]
}
</pre>


      </div>
    </div>
    <div v-show="macaujc3==1">
        <pre lay-skin="notepad"
             style="    font-family: Microsoft JhengHei,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Arial,sans-serif;;font-size: 25px;background: #ffffff;">
<div style="font-weight: 800;"><span>最新一期開獎接口:(平台盤口專用）：</span><a target="_blank" href="https://api.macaumarksix.com/api/macaujc.com"
                                                     style="color: #dd0c0c; display: inline-block">https://api.macaumarksix.com/api/macaujc.com</a>
        </div>
        <div style="font-weight: 800;"><span>一顆一顆開獎接口（資料網專用）：</span><a target="_blank" href="https://api.macaumarksix.com/api/live"
                                                          style="color: #dd0c0c; display: inline-block">https://api.macaumarksix.com/api/live</a>
        </div>
        <div style="font-weight: 800;"><span>歷史開獎接口：</span><a target="_blank" href="https://api.macaumarksix.com/history/macaujc/y/2024"
                                          style="color: #dd0c0c; display: inline-block">https://api.macaumarksix.com/history/macaujc/y/2024</a>
        </div>
        <div style="font-weight: 800;"><span>按期號查詢：</span><a target="_blank" href="https://api.macaumarksix.com/history/macaujc/expect/2024001"
                                        style="color: #dd0c0c; display: inline-block">https://api.macaumarksix.com/history/macaujc/expect/2024001</a>
        </div>
<pre style="text-align: left;" class="layui-code" lay-skin="notepad" lay-encode="true">

{
  "result": true,
  "message": "操作成功！",
  "code": 200,
  "data": [
    {
      "expect": "2024031",
      "openCode": "01,32,40,08,05,46,22",
      "zodiac": "兔,猴,鼠,猴,豬,馬,馬",
      "openTime": "2024-01-31 21:32:32",
      "wave": "red,green,red,red,green,red,green",
      "info": "數據來源macaujc.com 接口故障問題協助電報TG:macaumarksix"
    }
  ]
}
</pre>



  </pre>

    </div>


    <footer> 版權所有 不得轉載 @澳門六合彩 (macaujc.com)</footer>

  </div>

</template>

<style scoped src="../css/base_pc.css">
</style>
<style scoped>
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
}

.layui-code {
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: #3F3F3F;
  color: #c2be9e;
  font-size: 11px;
  font-family: monospace;
}
</style>