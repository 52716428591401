
<template>
  <div class="main" style="    background: #ffffff;">
    <div style="background: #ffffff">
      <img class="logo-img" style="border: none;width: 173px;height: 96px;margin-top: 20px;margin-bottom: 10px;"
           src="../img/macaujc.png" alt="">
    </div>

    <div class="daohang">
      <router-link to="/">
        <div style="background: #D90011;" class="daohangdiv">首頁</div>
      </router-link>
      <router-link to="/macaujc">
        <div style="background: #00A0EA;" class="daohangdiv">開獎公告</div>
      </router-link>
      <router-link to="/open_video">
        <div style="background: #17C001;" class="daohangdiv">開獎視頻</div>
      </router-link>
      <router-link to="/faq">
        <div style="background: #D9A400;" class="daohangdiv">FAQ</div>
      </router-link>
      <router-link to="/api">
        <div style="background: #692CC5;" class="daohangdiv">開獎調用</div>
      </router-link>
      <router-link to="/contact">
        <div style="background: #C42CB5;" class="daohangdiv dhBg">聯係我們</div>
      </router-link>
    </div>
    <div style="width: 100%;height: 5px;background: #eee222;margin-bottom: 11px;"></div>

    <div class="main_contact">
      <div class="title">
        聯繫我們
      </div>
      <div class="article_contact">
        彩種上架、廣告業務、反饋BUG、投訴建議、聯系澳門六合彩官方郵件。24小時內可以取得回復。
      </div>
      <ul class="contact">
        <li><img src="../img/email.png" alt=""></li>
        <li><span>E-mail：service@macaujc.com </span></li>
      </ul>
    </div>


    <footer> 版權所有 不得轉載 @澳門六合彩 (macaujc.com)</footer>

  </div>
</template>

<style scoped src="../css/base_pc.css"></style>
<style scoped src="../css/contact.css"></style>