<template>
  <div class="carousel-container" id="carousel">
    <div v-show="currentSlide === 0" class="carousel-slide active">
      <img src="../img/macaujc1.jpg" alt="">
    </div>
    <div v-show="currentSlide === 1"  class="carousel-slide" >
      <img src="../img/macaujc2.jpg" alt="">
    </div>
    <div v-show="currentSlide === 2"  class="carousel-slide" >
      <img src="../img/macaujc3.jpg" alt="">
    </div>
    <div v-show="currentSlide === 3"  class="carousel-slide" >
      <img src="../img/macaujc4.jpg" alt="">
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      currentSlide: 0,
      slides: [
        1,0,0,0
      ],
    };
  },
  mounted() {
    this.startCarousel();
  },
  methods: {
    startCarousel() {
      setInterval(() => {
        this.currentSlide = (this.currentSlide + 1) % this.slides.length;

      }, 4000); // Adjust the interval as needed
    },
  },
};
</script>
<style>
.carousel-slide{

}
.carousel-slide img{
  width: 100%;
}
</style>