<script src="../js/history_new_mac1.js">

</script>

<template>
  <div class="main">
    <div style="background: #ffffff">
      <img class="logo-img" style="border: none;width: 173px;height: 96px;margin-top: 20px;margin-bottom: 10px;"
           src="../img/macaujc.png" alt="">
    </div>
    <div class="daohang">
      <router-link to="/">
        <div style="background: #D90011;" class="daohangdiv">首頁</div>
      </router-link>
      <router-link to="/macaujc">
        <div style="background: #00A0EA;" class="daohangdiv dhBg">開獎公告</div>
      </router-link>
      <router-link to="/open_video">
        <div style="background: #17C001;" class="daohangdiv">開獎視頻</div>
      </router-link>
      <router-link to="/faq">
        <div style="background: #D9A400;" class="daohangdiv">FAQ</div>
      </router-link>
      <router-link to="/api">
        <div style="background: #692CC5;" class="daohangdiv">開獎調用</div>
      </router-link>
      <router-link to="/contact">
        <div style="background: #C42CB5;" class="daohangdiv">聯係我們</div>
      </router-link>
    </div>
    <div style="width: 100%;height: 5px;background: #eee222;margin-bottom: 11px;"></div>

    <div class="lotteryMain">開獎公告</div>
    <div class="fenlei">


      <router-link to="/macaujc">
        <div class="history_qiehuan" v-if="game_show[1] == '1'">
          <span class="">澳門六合彩</span>
        </div>
      </router-link>
      <router-link to="/macaujc2">
        <div class="history_qiehuan history_radius" v-if="game_show[2] === '1'">
          <span class="font-l">新澳門六合彩</span>
        </div>
      </router-link>
<!--      <router-link to="/macaujc3">-->
<!--        <div class="history_qiehuan" v-if="game_show[2] === '1'">-->
<!--          <span class="">澳門十點半</span>-->
<!--        </div>-->
<!--      </router-link>-->
    </div>


    <div class="open_code_zone" v-if="Object.keys(this_info).length > 0">
      <div class="open_code_zone_left">
        <div style="margin-left: 36px;font-size: 16px;"><span style="font-weight: bolder;">新澳門六合彩</span> <span
            style="font-size: 15px;margin-left: 20px;">第 <span
            style="color: #d21e1e;">{{ this_info.expect }}</span> 期</span></div>
        <div style="overflow: hidden;margin-top: 38px;margin-left: 22px;">
          <div class="history_jieguo" v-for="(value, ind) in this_info.openCode.split(',').slice(0, 6)" :key="ind">
            <div v-if="code_style!='0'" class="block-lump" :style='"background:" + return_wave(value)'>
              {{ format_number(value) }}
            </div>
            <div v-if="code_style=='0'" class="block-lump-circle"
                 :style='"background: " + code_img[return_wave(value)] + ";background-size: 100% 100%;"'>
              {{ format_number(value) }}
            </div>
            <div class="zodiac">{{ this_info.zodiac.split(',')[ind] }}</div>
          </div>
          <div class="history_add-logo">

            <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 8px; color: #da0f1f;"></i>
          </div>
          <div class="history_jieguo" v-for="(value, ind) in this_info.openCode.split(',').slice(6,7)" :key="ind">
            <div v-if="code_style!='0'" class="block-lump" :style='"background:" + return_wave(value)'>
              {{ format_number(value) }}
            </div>
            <div v-if="code_style=='0'" class="block-lump-circle"
                 :style='"background: " + code_img[return_wave(value)] + ";background-size: 100% 100%;"'>
              {{ format_number(value) }}
            </div>
            <div class="zodiac">{{ this_info.zodiac.split(',')[6] }}</div>
          </div>

          <span class="huifang2" @click="getInTimeOpenCode_review">模擬開獎</span>
        </div>
      </div>
      <div class="open_code_zone_right">
        <div class="lotteryTitle">
                <span>
                    <span class="period">第<span class="mred">{{ cal_next_expect(next_expect) }}</span></span>
                    <span>
                    <span><span class="mshow">期截止時間</span></span><i>{{ cal_next_time(this_info.openTime) }}</i>
                    </span>
                    </span>
        </div>
        <div class="countdown" id="countdown2">
          <span class="countdown-number">{{ countdown[0] }}</span>
          <span class="countdown-number">{{ countdown[1] }}</span>
          <span
              style="font-size: 38px;font-weight: 700;font-family: Microsoft JhengHei,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Arial,sans-serif;">:</span>
          <span class="countdown-number" style="margin-left: 0;">{{ countdown[2] }}</span>
          <span class="countdown-number">{{ countdown[3] }}</span>
          <span
              style="font-size: 38px;font-weight: 700;font-family: Microsoft JhengHei,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Arial,sans-serif;">:</span>
          <span class="countdown-number" style="margin-left: 0;">{{ countdown[4] }}</span>
          <span class="countdown-number">{{ countdown[5] }}</span>
        </div>

      </div>
    </div>

    <div class="beijing_xuhua">
      <div class="position_sett">
        <div style="color: #e21111;background: #fff;">位置</div>
        <div :class="set_b(0)" @click="transparent_code(0)">平一</div>
        <div :class="set_b(1)" @click="transparent_code(1)">平二</div>
        <div :class="set_b(2)" @click="transparent_code(2)">平三</div>
        <div :class="set_b(3)" @click="transparent_code(3)">平四</div>
        <div :class="set_b(4)" @click="transparent_code(4)">平五</div>
        <div :class="set_b(5)" @click="transparent_code(5)">平六</div>
        <div :class="set_b(6)" @click="transparent_code(6)">特碼</div>
        <div  @click="transparent_code(7)" class="huanyuan">還原</div>
      </div>

      <div class="position_sett">
        <div style="color: #e21111;background: #fff;">条件</div>
        <div :class="set_b_zo('鼠')" @click="transparent_zodiac('鼠')">鼠</div>
        <div :class="set_b_zo('牛')" @click="transparent_zodiac('牛')">牛</div>
        <div :class="set_b_zo('虎')" @click="transparent_zodiac('虎')">虎</div>
        <div :class="set_b_zo('兔')" @click="transparent_zodiac('兔')">兔</div>
        <div :class="set_b_zo('龍')" @click="transparent_zodiac('龍')">龍</div>
        <div :class="set_b_zo('蛇')" @click="transparent_zodiac('蛇')">蛇</div>
        <div :class="set_b_zo('馬')" @click="transparent_zodiac('馬')">馬</div>
        <div :class="set_b_zo('羊')" @click="transparent_zodiac('羊')">羊</div>
        <div :class="set_b_zo('猴')" @click="transparent_zodiac('猴')">猴</div>
        <div :class="set_b_zo('雞')" @click="transparent_zodiac('雞')">雞</div>
        <div :class="set_b_zo('狗')" @click="transparent_zodiac('狗')">狗</div>
        <div :class="set_b_zo('豬')" @click="transparent_zodiac('豬')">豬</div>
        <div  @click="transparent_zodiac(12)" class="huanyuan">還原</div>
      </div>
    </div>



    <div class="countdown-zone">
      <div class="find-div">
        <div style="font-weight: bolder;font-size: 16px;margin-left: 20px;">
          新澳門六合彩開獎公告
        </div>
        <div style="display: flex;margin-top: 15px;margin-bottom: 15px;left: 24%;position: absolute;">
          <div :class="{ 'last_year': true, 'this_year': this_year === 2024 }" @click="choose_history(2024)">2024年</div>
          <div :class="{ 'last_year': true, 'this_year': this.this_year === 2023 }" @click="choose_history(2023)">2023年</div>
          <div :class="{ 'last_year': true, 'this_year': this.this_year === 2022 }" @click="choose_history(2022)">2022年</div>
          <div :class="{ 'last_year': true, 'this_year': this.this_year === 2021 }" @click="choose_history(2021)">2021年</div>
        </div>
        <div class="find-input">
          <input class="expect_value" type="text" v-model="query_value" min="0" placeholder="期号">
          <img src="../img/查询.png" class="query_expect" alt="" @click="get_query_expect">
        </div>

      </div>
      <div class="hisWrapper">
        <div class="hisSubject">
          <div class="hisIssue">期號</div>
          <div class="hisTime">開獎時間</div>
          <div class="hisCode">中獎號碼</div>
          <div class="hisPlay">開獎回放</div>
        </div>
        <div class="hisList" v-for="(item, ind) in history" :key="ind">
          <div class="hisItem">
            <div class="hisIssue"><span> 第 <i>{{ item.expect }}</i> 期 </span></div>
            <div class="hisTime mhide"><span>{{ item.openTime }}</span></div>
            <div class="hisCode">
              <div class="small_open" v-if="code_style!='0'"
                   style="overflow: hidden;display: flex;justify-content: center;;-moz-transform: scale(0.45);zoom: 0.48;">
                <div class="history_jieguo" :class="transparent_code_list[ind] === 0 && transparent_zodiac_list.includes(item.zodiac.split(',')[ind]) ? '':'opacityOne'" v-for="(value, ind) in item.openCode.split(',').slice(0, 6)" :key="ind">
                  <div class="history_block-lump" :style='"background:" + item.wave.split(",")[ind]'>
                    {{ format_number(value) }}
                  </div>
                  <div class="history_zodiac">{{ item.zodiac.split(",")[ind] }}</div>
                </div>
                <div class="add-logo">
                  <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 8px; color: #da0f1f;line-height: 50px;"></i>
                </div>
                <div  class="history_jieguo" :class="transparent_code_list[6] === 0 && transparent_zodiac_list.includes(item.zodiac.split(',')[6])  ? '':'opacityOne'" v-for="(value, ind) in item.openCode.split(',').slice(6, 7)" :key="ind">
                  <div class="history_block-lump" :style='"background:" + item.wave.split(",")[6]'>
                    {{ format_number(value) }}
                  </div>
                  <div class="history_zodiac">{{ item.zodiac.split(",")[6] }}</div>
                </div>
              </div>
              <div class="small_open" v-if="code_style=='0'"
                   style="overflow: hidden;display: flex;justify-content: center;zoom: 0.8;align-items: center;">
                <div class="history_jieguo" :class="transparent_code_list[ind] === 0 && transparent_zodiac_list.includes(item.zodiac.split(',')[ind])  ? '':'opacityOne'" v-for="(value, ind) in item.openCode.split(',').slice(0, 6)" :key="ind">
                  <div class="history_block-lump-circle" :style='"background:" + code_img[item.wave.split(",")[ind]]'>
                    {{ format_number(value) }}
                  </div>
                  <div class="history_zodiac_circle">{{ item.zodiac.split(",")[ind] }}</div>
                </div>
                <div class="add-logo">
                  <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 8px; color: #da0f1f;line-height: 50px;"></i>
                </div>

                <div class="history_jieguo" :class="transparent_code_list[6] === 0 && transparent_zodiac_list.includes(item.zodiac.split(',')[6])  ? '':'opacityOne'" v-for="(value, ind) in item.openCode.split(',').slice(6, 7)" :key="ind">
                  <div class="history_block-lump-circle" :style='"background:" + code_img[item.wave.split(",")[6]]'>
                    {{ format_number(value) }}
                  </div>
                  <div class="history_zodiac_circle">{{ item.zodiac.split(",")[6] }}</div>
                </div>
              </div>
            </div>
            <div class="hisPlay mhide">
              <button class="button-primary video" @click="play_video(item)"><span>直播</span></button>
            </div>
          </div>
        </div>
      </div>

    </div>


    <footer> 版權所有 不得轉載 @澳門六合彩 (macaujc.com)</footer>

    <div id="video" v-if="video_cover === 1" >
      <div>
        <div style="display: flex;height: 63px;align-items: center;justify-content: flex-start;">
          <div style="margin-left: 36px;">
            <span class="name_lo">新澳門六合彩</span> <span
              style="font-size: 15px;margin-left: 20px;">第 <span style="color: #d21e1e;">{{ video_info.expect }}</span> 期</span>
            <span class="huifang">回放</span>
            <br>
            <span>開獎時間：</span> <span>{{ video_info.openTime }}</span>
          </div>
          <div v-if="code_style!='0' && video_info.openTime"
               style="overflow: hidden;display: flex;justify-content: center;;-moz-transform: scale(0.45);zoom: 0.48;margin-right: 30px;">
            <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(0, 6)" :key="ind">
              <div class="history_block-lump" :style='"background:" + video_info.wave.split(",")[ind]'>
                {{ format_number(value) }}
              </div>
              <div class="history_zodiac">{{ video_info.zodiac.split(",")[ind] }}</div>
            </div>
            <div class="add-logo">
              <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 8px; color: #da0f1f;line-height: 50px;"></i>
            </div>
            <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(6, 7)" :key="ind">
              <div class="history_block-lump" :style='"background:" + video_info.wave.split(",")[6]'>
                {{ format_number(value) }}
              </div>
              <div class="history_zodiac">{{ video_info.zodiac.split(",")[6] }}</div>
            </div>
          </div>

          <div v-if="code_style!='1' && video_info.openTime"
               style="overflow: hidden;display: flex;justify-content: center;zoom: 0.8;align-items: center;margin-right: 30px;">
            <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(0, 6)" :key="ind">
              <div class="history_block-lump-circle" :style='"background:" + code_img[video_info.wave.split(",")[ind]]'>
                {{ format_number(value) }}
              </div>
              <div class="history_zodiac">{{ video_info.zodiac.split(",")[ind] }}</div>
            </div>
            <div class="add-logo">
              <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 8px; color: #da0f1f;line-height: 50px;"></i>
            </div>
            <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(6, 7)" :key="ind">
              <div class="history_block-lump-circle" :style='"background:" + code_img[video_info.wave.split(",")[6]]'>
                {{ format_number(value) }}
              </div>
              <div class="history_zodiac">{{ video_info.zodiac.split(",")[6] }}</div>
            </div>
          </div>


          <img @click="close_video" src="../img/close.png" alt="" style="width: 30px;position: absolute;right: -9px;top: -7px;">
        </div>
      </div>
      <video :src="'https://macaujc.com/video2/'+video_info.expect+'.mp4'" autoplay="autoplay" style="" class="backV" id="play_video"></video>
    </div>

  </div>
</template>


<style scoped src="../css/history_pc.css"></style>