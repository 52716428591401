<script>
import axios from "axios";

export default {
  data() {
    return {
      remote_history: "https://api.macaumarksix.com",
      notice_list: []
    }
  },
  created() {
    // this.get_all_notice()
  },
  methods: {
    get_all_notice: function () {
      let that = this
      axios.get(that.remote_history + "/api/notice").then(function (response) {
        that.notice_list = response.data
      })
    }
  }
}
</script>

<template>
  <div class="boss">
    <div id="app" class="main">

      <div style="background: #ffffff">
        <img class="logo-img" src="../img/macaujc.png" alt="">
      </div>


      <input type="text" class="article_type" value="news" hidden>
      <div class="touma">官方公告</div>
      <div class="article" style="margin-top: 20px;">

        <div class="layui-panel">
          <ul class="layui-menu" id="docDemoMenu1">

            <li class="layui-menu-item-group">
              <div class="layui-menu-body-title "
                   style="font-size: 16px;height: 26px;line-height: 26px;color: #eb5e04;border-bottom: 1px solid #ddd;;">1.請使用谷歌網絡瀏覽器+全局VPN進入我們網站 <i class="layui-icon layui-icon-up"></i>
              </div>
              <ul>
                <li>
                  <pre class="layui-menu-body-title" style="line-height: 26px;font-size: 14px;">官網:macaujc.com 或macaulottery.com</pre>
                </li>
              </ul>
              <div class="layui-menu-body-title "
                   style="font-size: 16px;height: 26px;line-height: 26px;color: #eb5e04;border-bottom: 1px solid #ddd;;">2.澳門六合彩官方域名？<i class="layui-icon layui-icon-up"></i>
              </div>
              <ul>
                <li>
                  <pre class="layui-menu-body-title" style="line-height: 26px;font-size: 14px;">macaujc.com其他均為假冒</pre>
                </li>
              </ul>



              <div class="layui-menu-body-title "
                   style="font-size: 16px;height: 26px;line-height: 26px;color: #eb5e04;border-bottom: 1px solid #ddd;;">3.澳門六合彩開獎接口域名？ <i class="layui-icon layui-icon-up"></i>
              </div>
              <ul>
                <li>
                  <pre class="layui-menu-body-title" style="line-height: 26px;font-size: 14px;">macaumarksix.com澳門六合彩純英文其他均為假冒</pre>
                </li>
              </ul>



              <div class="layui-menu-body-title "
                   style="font-size: 16px;height: 26px;line-height: 26px;color: #eb5e04;border-bottom: 1px solid #ddd;;">4.反饋漏洞、投訴建議、廣告業務商務投資。  <i class="layui-icon layui-icon-up"></i>
              </div>
              <ul>
                <li>
                  <pre class="layui-menu-body-title" style="line-height: 26px;font-size: 14px;">E-mail：service@macaujc.com </pre>
                </li>
              </ul>

              <div class="layui-menu-body-title "
                   style="font-size: 16px;height: 26px;line-height: 26px;color: #eb5e04;border-bottom: 1px solid #ddd;;">5.澳門彩種上架由(MACAUJC)公開全球托管  <i class="layui-icon layui-icon-up"></i>
              </div>
              <ul>
                <li>
                  <pre class="layui-menu-body-title" style="line-height: 26px;font-size: 14px;">未在macaujc發布公證托管彩票均為假冒彩票</pre>
                </li>
              </ul>
            </li>


          </ul>
        </div>

      </div>
    </div>

    <div class="bottom_navi">
      <router-link to="/" style="display: contents;">
        <div class="bottom_navi_xuan">
          <i class="iconfont icon-shouye" style="font-size: 25px"></i>
          <div>首頁</div>
        </div>
      </router-link>
      <router-link to="/macaujc" style="display: contents;">
        <div class="bottom_navi_xuan">
          <i class="iconfont icon-trend" style="font-size: 25px"></i>
          <div>開獎历史</div>
        </div>
      </router-link>
      <router-link to="/open_video" style="display: contents;">
        <div class="bottom_navi_xuan">
          <i class="iconfont  icon-bofang" style="font-size: 25px"></i>
          <div>開獎直播</div>
        </div>
      </router-link>
      <router-link to="/api" style="display: contents;">
        <div class="bottom_navi_xuan">
          <i class="iconfont  icon-shujujiekou" style="font-size: 25px"></i>
          <div>開獎调用</div>
        </div>
      </router-link>
      <router-link to="/news" style="display: contents;">
        <div class="bottom_navi_xuan" style="background: red;">

          <i class="iconfont  icon-Rrl_s_152" style="font-size: 25px"></i>
          <div>官方公告</div>
        </div>
      </router-link>

    </div>
  </div>
</template>

<style scoped>
.touma {
  text-align: center;
  font-size: 25px;
  height: 40px;
  font-weight: 800;
  line-height: 40px;
  color: #fefffe;
  background: #0068b7;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 0px !important;
  margin-top: 4px;
  font: 14px Helvetica Neue,Helvetica,PingFang SC,Tahoma,Arial,sans-serif;
}

.bottom_navi {
  width: 100%;
  height: 60px;
  background: #1a1712a6;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.bottom_navi_xuan {
  width: 20%;
  height: 100%;
  background: #0068b7;
  color: #ffffff;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*box-shadow: 0 0 1px 1px #ddd inset;*/
  border: 0.1px solid #ddd;
}

.bottom_navi_xuan img {
  width: 60px;
  height: 60px;
  border-radius: 44px;
}
.logo-img {
  width: 84px;
  height: 47px;
}
ul{
  font-weight: 800;
}
li{
  list-style: none;
  margin-left: -18px;
}
</style>