

<template>
  <div id="app" class="main" style="    background: #ffffff;">
    <div style="background: #ffffff">
      <img class="logo-img" style="border: none;width: 173px;height: 96px;margin-top: 20px;margin-bottom: 10px;" src="../img/macaujc.png" alt="">
    </div>

    <div class="daohang">
      <router-link to="/">
        <div style="background: #D90011;" class="daohangdiv">首頁</div>
      </router-link>
      <router-link to="/macaujc">
        <div style="background: #00A0EA;" class="daohangdiv">開獎公告</div>
      </router-link>
      <router-link to="/open_video">
        <div style="background: #17C001;" class="daohangdiv dhBg">開獎視頻</div>
      </router-link>
      <router-link to="/faq">
        <div style="background: #D9A400;" class="daohangdiv">FAQ</div>
      </router-link>
      <router-link to="/api">
        <div style="background: #692CC5;" class="daohangdiv">開獎調用</div>
      </router-link>
      <router-link to="/contact">
        <div style="background: #C42CB5;" class="daohangdiv">聯係我們</div>
      </router-link>
    </div>
    <div style="width: 100%;height: 5px;background: #eee222;margin-bottom: 11px;"></div>

    <div class="lotteryMain">
      澳門六合彩
      <span class="notice">
            <span style="color: #a68452;">即時開獎驗證：</span>
            <span>開獎現場直播，同步播報中央電視臺1套視頻，開獎過程100%公開公正！</span>
        </span>
    </div>
    <div class="fenlei2">
      <router-link to="/open_video">
        <div class="history_qiehuan ">
          <span class="font-l" style="color: #000000">澳門六合彩</span>
        </div>
      </router-link>
      <router-link to="/open_video2">
        <div class="history_qiehuan history_radius">
          <span class="font-l">新澳門六合彩</span>
        </div>
      </router-link>
<!--      <router-link to="/open_video3">-->
<!--        <div class="history_qiehuan">-->
<!--          <span class="font-l" style="color: #000000">澳門十點半</span>-->
<!--        </div>-->
<!--      </router-link>-->

    </div>
    <Live2Pc/>
    <div class="open_code_zone"  style="border: 1px solid #ddd;" v-if="history.length>0">
      <div class="open_code_zone_left">
        <div style="margin-left: 36px;font-size: 16px;"><span style="font-weight: bolder;">新澳門六合彩</span> <span style="font-size: 15px;margin-left: 20px;">第 <span style="color: #d21e1e;">{{ deal_expect(next_expect, history[0].openTime) }}</span> 期</span></div>
        <div style="overflow: hidden;margin-top: 38px;margin-left: 22px;">
          <div class="history_jieguo" v-for="(value, ind) in currentOpenCode.slice(0, 6)" :key="ind">
            <div v-if="code_style!='0'" class="block-lump" :style='"background:" + return_wave(value)'>{{ format_number(value) }}</div>
            <div v-if="code_style=='0'" class="block-lump-circle" :style='"background: " + code_img[return_wave(value)] + ";background-size: 100% 100%;"'>{{ format_number(value) }}</div>
            <div class="zodiac">{{ returnShuXing(value, history[0].openTime) }}</div>
          </div>
          <div class="history_add-logo" v-show="currentOpenCode.length > 0">
            <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 15px; color: #da0f1f;"></i>
          </div>
          <div class="history_jieguo" v-for="(value, ind) in currentOpenCode.slice(6,7)" :key="ind">
            <div v-if="code_style!='0'" class="block-lump" :style='"background:" + return_wave(value)'>{{ format_number(value) }}</div>
            <div v-if="code_style=='0'" class="block-lump-circle" :style='"background: " + code_img[return_wave(value)] + ";background-size: 100% 100%;"'>{{ format_number(value) }}</div>
            <div class="zodiac">{{ returnShuXing(value, history[0].openTime) }}</div>
          </div>
          <span class="huifang2" @click="getInTimeOpenCode_review">模擬開獎</span>
        </div>
      </div>
      <div class="open_code_zone_right">
        <div class="lotteryTitle2">
                <span>
                    <span class="period">第<span class="mred">{{ cal_next_expect(next_expect) }}</span></span>
                    <span>
                    <span><span class="mshow">期截止時間</span></span><i>{{ cal_next_time(history[0].openTime) }}</i>
                    </span>
                    </span>
        </div>
        <div class="countdown" id="countdown2">
          <span class="countdown-number">{{ countdown[0] }}</span>
          <span class="countdown-number">{{ countdown[1] }}</span>
          <span style="font-size: 38px;font-weight: 700;font-family: Microsoft JhengHei,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Arial,sans-serif;">:</span>
          <span class="countdown-number" style="margin-left: 0;">{{ countdown[2] }}</span>
          <span class="countdown-number">{{ countdown[3] }}</span>
          <span style="font-size: 38px;font-weight: 700;font-family: Microsoft JhengHei,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Arial,sans-serif;">:</span>
          <span class="countdown-number" style="margin-left: 0;">{{ countdown[4] }}</span>
          <span class="countdown-number">{{ countdown[5] }}</span>
        </div>

      </div>
    </div>
    <div class="find-div">
      <div style="font-weight: bolder;font-size: 16px;margin-left: 20px;">
        新澳門六合彩 開獎視頻
      </div>
      <div style="display: flex;">
        <div style="display: flex;">

          <div class="find-input">
            <input class="expect_value" type="text" v-model="query_value" min="0" placeholder="期号">
            <input class="year_value" type="text" hidden>
            <img src="../img/查询.png" class="query_expect" @click="get_query_expect" alt="">
          </div>
        </div>

      </div>
    </div>
    <div style="display: flex;width: 1162px;flex-wrap: wrap;justify-content: space-around;border: 1px solid #ddd;">
      <div v-for="(lottery, index) in history" :key="index"  class="video_cover_par">
        <div class="video_cover">
          <img src="../img/play.png" alt="" class="play" expect="{{ lottery.expect }}" id="{{ lottery.id }}"
               @click="play_video(lottery)">
        </div>
        <div class="video_expect_number"><span>新澳門六合彩 第 </span><span class="seconde_span" >{{
            lottery.expect
          }}</span> <span> 期 開獎視頻</span></div>

      </div>
    </div>


    <footer> 版權所有 不得轉載 @澳門六合彩 (macaujc.com)</footer>

    <div id="video" v-if="video_cover === 1" >
      <div>
        <div style="display: flex;height: 63px;align-items: center;justify-content: flex-start;">
          <div style="margin-left: 36px;">
            <span class="name_lo">新澳門六合彩</span> <span
              style="font-size: 15px;margin-left: 20px;">第 <span style="color: #d21e1e;">{{ video_info.expect }}</span> 期</span>
            <span class="huifang">回放</span>
            <br>
            <span>開獎時間：</span> <span>{{ video_info.openTime }}</span>
          </div>
          <div v-if="code_style!='0' && video_info.openTime"
               style="overflow: hidden;display: flex;justify-content: center;;-moz-transform: scale(0.45);zoom: 0.78;margin-right: 30px;">
            <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(0, 6)" :key="ind">
              <div class="history_block-lump" :style='"background:" + video_info.wave.split(",")[ind]'>
                {{ format_number(value) }}
              </div>
              <div class="history_zodiac">{{ video_info.zodiac.split(",")[ind] }}</div>
            </div>
            <div class="add-logo">
              <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 8px; color: #da0f1f;"></i>
            </div>
            <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(6, 7)" :key="ind">
              <div class="history_block-lump" :style='"background:" + video_info.wave.split(",")[6]'>
                {{ format_number(value) }}
              </div>
              <div class="history_zodiac">{{ video_info.zodiac.split(",")[6] }}</div>
            </div>
          </div>

          <div v-if="code_style=='0' && video_info.openTime"
               style="overflow: hidden;display: flex;justify-content: center;zoom: 0.8;align-items: center;margin-right: 30px;">
            <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(0, 6)" :key="ind">
              <div class="history_block-lump-circle" :style='"background:" + code_img[video_info.wave.split(",")[ind]]'>
                {{ format_number(value) }}
              </div>
              <div class="history_zodiac">{{ video_info.zodiac.split(",")[ind] }}</div>
            </div>
            <div class="add-logo">
              <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 8px; color: #da0f1f;"></i>
            </div>
            <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(6, 7)" :key="ind">
              <div class="history_block-lump-circle" :style='"background:" + code_img[video_info.wave.split(",")[6]]'>
                {{ format_number(value) }}
              </div>
              <div class="history_zodiac">{{ video_info.zodiac.split(",")[6] }}</div>
            </div>
          </div>


          <img @click="close_video" src="../img/close.png" alt="" style="width: 30px;position: absolute;right: -9px;top: -7px;">
        </div>
      </div>
      <div class="loader" v-if="!isVideoLoaded"></div>
      <video :src="'https://macaujc.com/video2/'+video_info.expect+'.mp4'"  @loadeddata="handleVideoLoaded" autoplay="autoplay" style="" class="backV" id="play_video"></video>

    </div>
  </div>
</template>

<style scoped src="../css/base_pc.css"></style>
<style scoped>
.video_cover_par {
  margin-top: 7px;
  width: 354px !important;
  height: 200px !important;
}
.video_cover  {
  background: url(../img/repeat.png);
  width: 354px  !important;
  height: 180px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
}
.video_cover img[data-v-862dbbb8] {
  width: 87px !important;
}

.video_expect_number {
  width: 354px;
  text-align: center;
  font-size: 12px;
  color: #414141;
}
</style>
<style scoped src="../css/video_pc.css"></style>
<script src="../js/video2Pc.js"></script>