<template>
  <div class="main">
    <div style="background: #ffffff;display:flex;justify-content: space-between;align-items: flex-end;">
      <img class="logo-img" style="border: none;width: 173px;height: 96px;margin-top: 20px;margin-bottom: 10px;"
           src="../img/macaujc.png" alt="">
      <div
          style="display: flex; align-items: flex-start; font-size: 38px; flex-direction: column;  font-weight: 800;    width: 48%;    color: #e70707;">
      </div>
      <div class="switch_style" @click="switchOpenCodeStyle">切換開獎風格</div>
    </div>

    <div class="daohang">
      <router-link to="/">
        <div style="background: #D90011;" class="daohangdiv dhBg">首頁</div>
      </router-link>
      <router-link to="/macaujc">
        <div style="background: #00A0EA;" class="daohangdiv">開獎公告</div>
      </router-link>
      <router-link to="/open_video">
        <div style="background: #17C001;" class="daohangdiv">開獎視頻</div>
      </router-link>
      <router-link to="/faq">
        <div style="background: #D9A400;" class="daohangdiv">FAQ</div>
      </router-link>
      <router-link to="/api">
        <div style="background: #692CC5;" class="daohangdiv">開獎調用</div>
      </router-link>
      <router-link to="/contact">
        <div style="background: #C42CB5;" class="daohangdiv">聯係我們</div>
      </router-link>
    </div>

    <div style="width: 100%;height: 5px;background: #eee222;margin-bottom: 11px;"></div>


    <Carousel/>

    <div v-if="game_show[1] == '1'">
      <div class="open_zone">
        <div class="lotteryTitle">
          <b s style="color: black;font-weight: 700">澳門六合彩22:30</b>
          <span>
                    <span class="period">第<span class="mred">{{ cal_next_expect(expect_macaoDh) }}</span>期</span>
                    <span>
                    <span><span class="mshow">下期</span>截止時間<span
                        class="mhide">：</span></span><i>{{ cal_next_time_shidian(openTime_macaoDh) }}</i>
                    </span>
                    </span>
        </div>
        <div class="open_code_zone">
          <div class="open_code_zone_left">
            <div style="margin-left: 36px;"><span class="name_lo">澳門六合彩</span> <span
                style="font-size: 15px;margin-left: 20px;">第 <span style="color: #d21e1e;">{{ expect_macaoDh }}</span> 期</span>
            </div>
            <div style="overflow: hidden;margin-top: 38px;margin-left: 22px;">
              <div class="history_jieguo_a" v-for="(value, ind) in currentOpenCode_macaoDh.slice(0, 6)" :key="ind">
                <div v-if="code_style!='0'" class="block-lump" :style='"background:" + return_wave(value)'>
                  {{ value }}
                </div>
                <div v-if="code_style=='0'" class="block-lump-circle"
                     :style='"background: " + code_img[return_wave(value)] + ";background-size: 100% 100%;"'>
                  {{ value }}
                </div>
                <div class="zodiac">{{ returnShuXing(value, openTime_macaoDh) }}</div>
              </div>
              <div class="history_add-logo" v-show="currentOpenCode_macaoDh.length > 0">
                <!--                  <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 16px; color: #da0f1f;"></i>-->
                <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 16px; color: #da0f1f;"></i>
              </div>
              <div class="history_jieguo_a" v-for="(value, ind) in currentOpenCode_macaoDh.slice(6,7)" :key="ind">
                <div v-if="code_style!='0'" class="block-lump" :style='"background:" + return_wave(value)'>
                  {{ value }}
                </div>
                <div v-if="code_style=='0'" class="block-lump-circle"
                     :style='"background: " + code_img[return_wave(value)] + ";background-size: 100% 100%;"'>
                  {{ value }}
                </div>
                <div class="zodiac">{{ returnShuXing(value, openTime_macaoDh) }}</div>
              </div>
              <span class="huifang2" @click="getInTimeOpenCode">模擬開獎</span>
            </div>

          </div>
          <div class="open_code_zone_right">
            <div class="countdown" id="countdown2">
              <span class="countdown-number">{{ countdown_macaujc[0] }}</span>
              <span class="countdown-number">{{ countdown_macaujc[1] }}</span>
              <span
                  style="font-size: 38px;font-weight: 700;font-family: Microsoft JhengHei,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Arial,sans-serif;">:</span>
              <span class="countdown-number" style="margin-left: 0;">{{ countdown_macaujc[2] }}</span>
              <span class="countdown-number">{{ countdown_macaujc[3] }}</span>
              <span
                  style="font-size: 38px;font-weight: 700;font-family: Microsoft JhengHei,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Arial,sans-serif;">:</span>
              <span class="countdown-number" style="margin-left: 0;">{{ countdown_macaujc[4] }}</span>
              <span class="countdown-number">{{ countdown_macaujc[5] }}</span>
            </div>
            <div style="margin-top: 20px;">
              <a href="//macaujc.com" target="_blank">
                <div class="find">開獎驗證</div>
              </a>
              <router-link to="/open_video">
                <div class="source">直播</div>
              </router-link>
              <router-link to="/macaujc">
                <div>
                  <div style="color: #000000;font-size: 16px;display: inline-block;margin-top: 20px;">開獎歷史查詢</div>
                  <i class="layui-icon" style="font-size: 16px; color: #da0f1f;">&gt;</i>
                </div>
              </router-link>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="game_show[2] == '1'">
      <div class="open_zone">
        <div class="lotteryTitle">
          <b style="color: blue;;font-weight: 700">新澳門六合彩</b>
          <span>
                    <span class="period">第<span class="mred">{{ cal_next_expect(expect_macaoDh_new) }}</span>期</span>
                    <span>
                    <span><span class="mshow">下期</span>截止時間<span
                        class="mhide">：</span></span><i>{{ cal_next_time(openTime_macaoDh_new) }}</i>
                    </span>
                    </span>
        </div>
        <div class="open_code_zone">
          <div class="open_code_zone_left">
            <div style="margin-left: 36px;"><span class="name_lo">新澳門六合彩</span> <span
                style="font-size: 15px;margin-left: 20px;">第 <span style="color: #d21e1e;">{{
                expect_macaoDh_new
              }}</span> 期</span></div>
            <div class="code_mark" style="overflow: hidden;margin-top: 38px;margin-left: 22px;">
              <div class="history_jieguo_a" v-for="(value, ind) in currentOpenCode_macaoDh_new.slice(0, 6)" :key="ind">
                <div v-if="code_style!='0'" class="block-lump" :style='"background:" + return_wave(value)'>
                  {{ value }}
                </div>
                <div v-if="code_style=='0'" class="block-lump-circle"
                     :style='"background: " + code_img[return_wave(value)] + ";background-size: 100% 100%;"'>
                  {{ value }}
                </div>
                <div class="zodiac">{{ returnShuXing(value, openTime_macaoDh_new) }}</div>
              </div>
              <div class="history_add-logo" v-show="currentOpenCode_macaoDh_new.length > 0">
                <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 16px; color: #da0f1f;"></i>
              </div>
              <div class="history_jieguo_a" v-for="(value, ind) in currentOpenCode_macaoDh_new.slice(6,7)" :key="ind">
                <div v-if="code_style!='0'" class="block-lump" :style='"background:" + return_wave(value)'>
                  {{ value }}
                </div>
                <div v-if="code_style=='0'" class="block-lump-circle"
                     :style='"background: " + code_img[return_wave(value)] + ";background-size: 100% 100%;"'>
                  {{ value }}
                </div>
                <div class="zodiac">{{ returnShuXing(value, openTime_macaoDh_new) }}</div>
              </div>

              <span class="huifang2" @click="getInTimeOpenCode_new">模擬開獎</span>
            </div>
          </div>
          <div class="open_code_zone_right">
            <div class="countdown" id="countdown3">
              <span class="countdown-number">{{ countdown_macaujc_new[0] }}</span>
              <span class="countdown-number">{{ countdown_macaujc_new[1] }}</span>
              <span
                  style="font-size: 38px;font-weight: 700;font-family: Microsoft JhengHei,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Arial,sans-serif;">:</span>
              <span class="countdown-number" style="margin-left: 0;">{{ countdown_macaujc_new[2] }}</span>
              <span class="countdown-number">{{ countdown_macaujc_new[3] }}</span>
              <span
                  style="font-size: 38px;font-weight: 700;font-family: Microsoft JhengHei,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Arial,sans-serif;">:</span>
              <span class="countdown-number" style="margin-left: 0;">{{ countdown_macaujc_new[4] }}</span>
              <span class="countdown-number">{{ countdown_macaujc_new[5] }}</span>
            </div>
            <div style="margin-top: 20px;">
              <router-link to="//maucaujc.com" target="_blank">
                <div class="find">開獎驗證</div>
              </router-link>
              <router-link to="/open_video2">
                <div class="source">直播</div>
              </router-link>
              <router-link to="/macaujc2">
                <div>
                  <div style="color: #000000;font-size: 16px;display: inline-block;margin-top: 20px;">開獎歷史查詢</div>
                  <i class="layui-icon" style="font-size: 16px; color: #da0f1f;">&gt;</i>
                </div>
              </router-link>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="game_show[3] == '1'">
      <div class="open_zone">
        <div class="lotteryTitle">
          <b style="color: blue;;font-weight: 700">澳門十點半</b>
          <span>
                    <span class="period">第<span class="mred">{{ cal_next_expect(expect_shidian) }}</span>期</span>
                    <span>
                    <span><span class="mshow">下期</span>截止時間<span
                        class="mhide">：</span></span><i>{{ cal_next_time_shidian(openTime_shidian) }}</i>
                    </span>
                    </span>
        </div>
        <div class="open_code_zone">
          <div class="open_code_zone_left">
            <div style="margin-left: 36px;"><span class="name_lo">澳門十點半</span> <span
                style="font-size: 15px;margin-left: 20px;">第 <span style="color: #d21e1e;">{{ expect_shidian }}</span> 期</span>
            </div>
            <div class="code_mark" style="overflow: hidden;margin-top: 38px;margin-left: 22px;">
              <div class="history_jieguo_a" v-for="(value, ind) in currentOpenCode_shidian.slice(0, 6)" :key="ind">
                <div v-if="code_style!='0'" class="block-lump" :style='"background:" + return_wave(value)'>
                  {{ value }}
                </div>
                <div v-if="code_style=='0'" class="block-lump-circle"
                     :style='"background: " + code_img[return_wave(value)] + ";background-size: 100% 100%;"'>
                  {{ value }}
                </div>
                <div class="zodiac">{{ returnShuXing(value, openTime_shidian) }}</div>
              </div>
              <div class="history_add-logo" v-show="currentOpenCode_shidian.length > 0">
                <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 16px; color: #da0f1f;"></i>
              </div>
              <div class="history_jieguo_a" v-for="(value, ind) in currentOpenCode_shidian.slice(6,7)" :key="ind">
                <div v-if="code_style!='0'" class="block-lump" :style='"background:" + return_wave(value)'>
                  {{ value }}
                </div>
                <div v-if="code_style=='0'" class="block-lump-circle"
                     :style='"background: " + code_img[return_wave(value)] + ";background-size: 100% 100%;"'>
                  {{ value }}
                </div>
                <div class="zodiac">{{ returnShuXing(value, openTime_shidian) }}</div>
              </div>

              <span class="huifang2" @click="getInTimeOpenCode_shidian">模擬開獎</span>
            </div>
          </div>
          <div class="open_code_zone_right">
            <div class="countdown" id="countdown4">
              <span class="countdown-number">{{ countdown_macaujc_shidian[0] }}</span>
              <span class="countdown-number">{{ countdown_macaujc_shidian[1] }}</span>
              <span
                  style="font-size: 38px;font-weight: 700;font-family: Microsoft JhengHei,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Arial,sans-serif;">:</span>
              <span class="countdown-number" style="margin-left: 0;">{{ countdown_macaujc_shidian[2] }}</span>
              <span class="countdown-number">{{ countdown_macaujc_shidian[3] }}</span>
              <span
                  style="font-size: 38px;font-weight: 700;font-family: Microsoft JhengHei,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Arial,sans-serif;">:</span>
              <span class="countdown-number" style="margin-left: 0;">{{ countdown_macaujc_shidian[4] }}</span>
              <span class="countdown-number">{{ countdown_macaujc_shidian[5] }}</span>
            </div>
            <div style="margin-top: 20px;">
              <router-link to="//maucaujc.com" target="_blank">
                <div class="find">開獎驗證</div>
              </router-link>
              <router-link to="/open_video3">
                <div class="source">直播</div>
              </router-link>
              <router-link to="/macaujc3">
                <div>
                  <div style="color: #000000;font-size: 16px;display: inline-block;margin-top: 20px;">開獎歷史查詢</div>
                  <i class="layui-icon" style="font-size: 16px; color: #da0f1f;">&gt;</i>
                </div>
              </router-link>

            </div>
          </div>
        </div>
      </div>
    </div>


    <!--    历史开奖-->
    <div class="fenlei">

      <div :class="history_show_1 == 1 ?'history_qiehuan history_radius' : 'history_qiehuan'"
           v-if="game_show[1] === '1'" @click="switch_history(0)">
        <span class="font-l">澳門六合彩</span>
      </div>
      <div :class="history_show_2 == 1 ?'history_qiehuan history_radius' : 'history_qiehuan'"
           v-if="game_show[2] === '1'" @click="switch_history(1)">
        <span class="font-l">新澳門六合彩</span>
      </div>
      <!--        <div :class="history_show_3 == 1 ?'history_qiehuan history_radius' : 'history_qiehuan'" v-if="game_show[3] === '1'" @click="switch_history(2)">-->
      <!--          <span class="font-l">澳門十點半</span>-->
      <!--        </div>-->
    </div>

    <div class="hisWrapper" v-show="history_show_1===1">
      <div class="hisSubject">
        <div class="hisIssue">期號</div>
        <div class="hisTime">開獎時間</div>
        <div class="hisCode">中獎號碼</div>
        <div class="hisPlay">開獎回放</div>
      </div>
      <div class="hisList" v-for="(item, index) in history_2.slice(0,5)" :key="index">
        <div class="hisItem">
          <div class="hisIssue"><span> 第 <i>{{ item.expect }}</i> 期 </span></div>
          <div class="hisTime mhide"><span>{{ item.openTime }}</span></div>
          <div class="hisCode">
            <div style="overflow: hidden;display: flex;justify-content: center;">
              <div class="history_jieguo" v-for="(value, ind) in item.openCode.split(',').slice(0, 6)" :key="ind">
                <div v-if="code_style!='0'" class="history_block-lump"
                     :style='"background:" + item.wave.split(",")[ind]'>{{ value }}
                </div>
                <div v-if="code_style=='0'" class="history_block-lump-circle"
                     :style='"background:" + code_img[item.wave.split(",")[ind]]'>{{ value }}
                </div>
                <div class="history_zodiac">{{ item.zodiac.split(",")[ind] }}</div>
              </div>
              <div class="add-logo">
                <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 8px; color: #da0f1f;"></i>
              </div>

              <div class="history_jieguo" v-for="(value, ind) in item.openCode.split(',').slice(6, 7)" :key="ind">
                <div v-if="code_style!='0'" class="history_block-lump"
                     :style='"background:" + item.wave.split(",")[6]'>{{ value }}
                </div>
                <div v-if="code_style=='0'" class="history_block-lump-circle"
                     :style='"background:" + code_img[item.wave.split(",")[6]]'>{{ value }}
                </div>
                <div class="history_zodiac">{{ item.zodiac.split(",")[6] }}</div>
              </div>
            </div>
          </div>
          <div class="hisPlay mhide">
            <button class="button-primary video" @click="play_video(item)"><span>直播</span></button>
          </div>
        </div>
      </div>
    </div>
    <div class="hisWrapper" v-show="history_show_2==1">
      <div class="hisSubject">
        <div class="hisIssue">期號</div>
        <div class="hisTime">開獎時間</div>
        <div class="hisCode">中獎號碼</div>
        <div class="hisPlay">開獎回放</div>
      </div>
      <div class="hisList" v-for="(item, index) in history_3.slice(0,5)" :key="index">
        <div class="hisItem">
          <div class="hisIssue"><span> 第 <i>{{ item.expect }}</i> 期 </span></div>
          <div class="hisTime mhide"><span>{{ item.openTime }}</span></div>
          <div class="hisCode">
            <div style="overflow: hidden;display: flex;justify-content: center;">
              <div class="history_jieguo" v-for="(value, ind) in item.openCode.split(',').slice(0, 6)" :key="ind">
                <div v-if="code_style!=='0'" class="history_block-lump"
                     :style='"background:" + item.wave.split(",")[ind]'>{{ value }}
                </div>
                <div v-if="code_style==='circle'" class="history_block-lump-circle"
                     :style='"background:" + code_img[item.wave.split(",")[ind]]'>{{ value }}
                </div>
                <div class="history_zodiac">{{ item.zodiac.split(",")[ind] }}</div>
              </div>
              <div class="add-logo">
                <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 8px; color: #da0f1f;"></i>
              </div>

              <div class="history_jieguo" v-for="(value, ind) in item.openCode.split(',').slice(6, 7)" :key="ind">
                <div v-if="code_style!='0'" class="history_block-lump"
                     :style='"background:" + item.wave.split(",")[6]'>{{ value }}
                </div>
                <div v-if="code_style=='0'" class="history_block-lump-circle"
                     :style='"background:" + code_img[item.wave.split(",")[6]]'>{{ value }}
                </div>
                <div class="history_zodiac">{{ item.zodiac.split(",")[6] }}</div>
              </div>
            </div>
          </div>
          <div class="hisPlay mhide">
            <button class="button-primary video" @click="play_video(item)"><span>直播</span></button>
          </div>
        </div>
      </div>
    </div>
    <div class="hisWrapper" v-show="history_show_3==1">
      <div class="hisSubject">
        <div class="hisIssue">期號</div>
        <div class="hisTime">開獎時間</div>
        <div class="hisCode">中獎號碼</div>
        <div class="hisPlay">開獎回放</div>
      </div>
      <div class="hisList" v-for="(item, ind) in history_4.slice(0,5)" :key="ind">
        <div class="hisItem">
          <div class="hisIssue"><span> 第 <i>{{ item.expect }}</i> 期 </span></div>
          <div class="hisTime mhide"><span>{{ item.openTime }}</span></div>
          <div class="hisCode">
            <div style="overflow: hidden;display: flex;justify-content: center;">
              <div class="history_jieguo" v-for="(value, ind) in item.openCode.split(',').slice(0, 6)" :key="ind">
                <div v-if="code_style!='0'" class="history_block-lump"
                     :style='"background:" + item.wave.split(",")[ind]'>{{ value }}
                </div>
                <div v-if="code_style=='0'" class="history_block-lump-circle"
                     :style='"background:" + code_img[item.wave.split(",")[ind]]'>{{ value }}
                </div>
                <div class="history_zodiac">{{ item.zodiac.split(",")[ind] }}</div>
              </div>
              <div class="add-logo">
                <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 8px; color: #da0f1f;"></i>
              </div>

              <div class="history_jieguo" v-for="(value, ind) in item.openCode.split(',').slice(6, 7)" :key="ind">
                <div v-if="code_style!='0'" class="history_block-lump"
                     :style='"background:" + item.wave.split(",")[6]'>{{ value }}
                </div>
                <div v-if="code_style=='0'" class="history_block-lump-circle"
                     :style='"background:" + code_img[item.wave.split(",")[6]]'>{{ value }}
                </div>
                <div class="history_zodiac">{{ item.zodiac.split(",")[6] }}</div>
              </div>
            </div>
          </div>
          <div class="hisPlay mhide">
            <button class="button-primary video" @click="play_video(item)"><span>直播</span></button>
          </div>
        </div>
      </div>
    </div>



    <footer> 版權所有 不得轉載 @澳門六合彩 (macaujc.com)</footer>


    <div id="video" v-if="video_cover === 1" >
      <div>
        <div style="display: flex;height: 63px;align-items: center;justify-content: flex-start;">
          <div style="margin-left: 36px;">
            <span class="name_lo">{{ video_info.title }}</span> <span
              style="font-size: 15px;margin-left: 20px;">第 <span style="color: #d21e1e;">{{ video_info.expect }}</span> 期</span>
            <span class="huifang">回放</span>
            <br>
            <span>開獎時間：</span> <span>{{ video_info.openTime }}</span>
          </div>
          <div v-if="code_style!='0' && video_info.openTime"
               style="overflow: hidden;display: flex;justify-content: center;;-moz-transform: scale(0.45);zoom: 0.68;margin-right: 30px;">
            <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(0, 6)" :key="ind">
              <div class="history_block-lump" :style='"background:" + video_info.wave.split(",")[ind]'>
                {{ value }}
              </div>
              <div class="history_zodiac">{{ video_info.zodiac.split(",")[ind] }}</div>
            </div>
            <div class="add-logo">
              <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 8px; color: #da0f1f;"></i>
            </div>
            <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(6, 7)" :key="ind">
              <div class="history_block-lump" :style='"background:" + video_info.wave.split(",")[6]'>
                {{ value }}
              </div>
              <div class="history_zodiac">{{ video_info.zodiac.split(",")[6] }}</div>
            </div>
          </div>

          <div v-if="code_style!='1' && video_info.openTime"
               style="overflow: hidden;display: flex;justify-content: center;zoom: 0.8;align-items: center;margin-right: 30px;">
            <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(0, 6)" :key="ind">
              <div class="history_block-lump-circle" :style='"background:" + code_img[video_info.wave.split(",")[ind]]'>
                {{ value }}
              </div>
              <div class="history_zodiac">{{ video_info.zodiac.split(",")[ind] }}</div>
            </div>
            <div class="add-logo">
              <i class="iconfont icon-jiajianzujianjiahao" style="font-size: 8px; color: #da0f1f;"></i>
            </div>
            <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(6, 7)" :key="ind">
              <div class="history_block-lump-circle" :style='"background:" + code_img[video_info.wave.split(",")[6]]'>
                {{ value }}
              </div>
              <div class="history_zodiac">{{ video_info.zodiac.split(",")[6] }}</div>
            </div>
          </div>


          <img @click="close_video" src="../img/close.png" alt="" style="width: 30px;position: absolute;right: -9px;top: -7px;">
        </div>
      </div>
      <video :src="'https://macaujc.com/' + video_info.video_type + '/'+video_info.expect+'.mp4'" autoplay="autoplay" style="" class="backV" id="play_video"></video>
    </div>
  </div>
</template>

<script src="../js/basePc.js"></script>

<style scoped src="../css/base_pc.css"></style>