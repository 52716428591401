<script>
  export default {
    props: {
      video_info: JSON
    },
     data(){
       return{

         code_style: localStorage.getItem("code_style_square"),
       }
     }
  }
</script>

<template>
  <div id="video">
    <div>
      <div style="display: flex;height: 63px;align-items: center;justify-content: flex-start;">
        <div style="margin-left: 36px;">
          <span class="name_lo"> {{ video_info.type === 'macaujc'? '澳門六合彩' : '新澳門六合彩'}}</span> <span style="font-size: 15px;margin-left: 20px;">第 <font style="color: #d21e1e;">{{ video_info.expect }}</font> 期</span>
          <span class="huifang">回放</span>
          <br>
          <span>開獎時間：</span> <span>{{ video_info.openTime }}</span>
        </div>
        <div v-if="code_style!=='0' && video_info.openTime" style="overflow: hidden;display: flex;justify-content: center;;-moz-transform: scale(0.45);zoom: 0.48;margin-right: 30px;" >
          <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(0, 6)" :key="ind">
            <div class="history_block-lump" :style='"background:" + video_info.wave.split(",")[ind]'>{{ format_number(value) }}</div>
            <div class="history_zodiac">{{ video_info.zodiac.split(",")[ind] }}</div>
          </div>
          <div class="add-logo">
            <i class="layui-icon" style="font-size: 12px; color: #da0f1f;">&#xe654;</i>
          </div>
          <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(6, 7)" :key="ind">
            <div class="history_block-lump" :style='"background:" + video_info.wave.split(",")[6]'>{{ format_number(value) }}</div>
            <div class="history_zodiac">{{ video_info.zodiac.split(",")[6] }}</div>
          </div>
        </div>


        <div  v-if="code_style=='1' && video_info.openTime" style="overflow: hidden;display: flex;justify-content: center;zoom: 0.8;align-items: center;margin-right: 30px;">
          <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(0, 6)" :key="ind">
            <div class="history_block-lump-circle" :style='"background:" + code_img[video_info.wave.split(",")[ind]]'>{{ format_number(value) }}</div>
            <div class="history_zodiac">{{ video_info.zodiac.split(",")[ind] }}</div>
          </div>
          <div class="add-logo">
            <i class="layui-icon" style="font-size: 20px; color: #da0f1f;">&#xe654;</i>
          </div>
          <div class="history_jieguo" v-for="(value, ind) in video_info.openCode.split(',').slice(6, 7)" :key="ind">
            <div class="history_block-lump-circle" :style='"background:" + code_img[video_info.wave.split(",")[6]]'>{{ format_number(value) }}</div>
            <div class="history_zodiac">{{ video_info.zodiac.split(",")[6] }}</div>
          </div>
        </div>
      </div>
    </div>
    <video src="" autoplay="autoplay" style="" class="backV" id="play_video"></video>
  </div>
</template>

<style scoped>

</style>