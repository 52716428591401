GJPGameTypeGJPGameTypeGJPGameType<script>
import flvjs from 'flv.js';
import Hls from "hls.js";

import CryptoJS from 'crypto-js';

export default {
  data() {
    return {
      video_cover_info: 1,
      centeredVideo: 0,
      voice: 0,
      xiegang: 0,

      targetTime: new Date().setHours(22, 30, 0),
      formattedTime: '', // 初始时间

      live_line : localStorage.getItem("live_line_old"),
      secret:"U2FsdGVkX1+nh4f4aJnYMTK2WMqxsC5mHn53HMBqzU2negOSt/mdqvjH5M5p+aKtpnAQzGx++O7vQjEpKqQXsDNErNQHgkDVQbBsYZLyA/M=",

      videoElement: '',
      flvPlayer:'',
      hls:'',
    }
  },
  created() {

  },
  mounted() {

    this.liveCountDown();

  },
  methods: {
    liveCountDown() {
      const currentTime = Date.now();
      const timeDifference = this.targetTime - currentTime;
      if (timeDifference > 0) {
        const hours = Math.floor(timeDifference / 3600000);
        const minutes = Math.floor((timeDifference % 3600000) / 60000);
        const seconds = Math.floor((timeDifference % 60000) / 1000);
        // 格式化时间为 "00:00:00"
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');
        this.formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        setTimeout(this.liveCountDown, 1000); // 每秒更新一次
      } else {
        let n = new Date()
        if (n.getHours() <= 22 && n.getMinutes() <=38){
          if (this.checkIfIOS()){
            this.liveUrl = this.decrypt(this.secret) + '.m3u8'
            this.play_live_ios(this.liveUrl)
          } else{
            this.liveUrl = this.decrypt(this.secret) + '.flv'
            this.play_live_other(this.liveUrl)
          }
          this.formattedTime = '倒计时结束'; // 倒计时结束后的文本
        }

      }
    },
    open_voice: function () {
      const player = document.getElementById('videoElement');
      // 判断元素是否隐藏
      if (this.xiegang === 0) {
        // 元素隐藏时执行的代码
        this.xiegang = 1
        player.muted = true
      } else {
        // 元素可见时执行的代码
        this.xiegang = 0
        player.muted = false
      }
    },
    slv_video: function (url) {
      var reload = 0
      var player = document.getElementById('videoElement');
      if (flvjs.isSupported()) {
        var flvPlayer = flvjs.createPlayer({
          type: 'flv',
          "isLive": true,
          "enableStashBuffer": false,
          url: url,
          hasVideo: function (e) {
            console.log(e)
          },
          onMediaInfo: function () {
            player.offsetWidth = 500
            player.offsetHeight = 500
          }
        });


        flvPlayer.on(flvjs.Events.ERROR, (errorType, errorDetail) => {
          console.log(`flv.js error: ${errorType} - ${errorDetail}`);
          reload += 1
          if (reload >= 5) {
            flvPlayer.unload();
            flvPlayer.detachMediaElement();
            flvPlayer.attachMediaElement(player);
            flvPlayer.load();
            flvPlayer.play();
          }

        });

        flvPlayer.on(flvjs.Events.LOADING_COMPLETE, () => {
          console.log('flv.js loading complete');
        });

        flvPlayer.on(flvjs.Events.METADATA_ARRIVED, () => {
          this.video_cover_info = 0
          this.centeredVideo = 1
          this.voice = 1
          this.xiegang = 1
        });


        flvPlayer.attachMediaElement(player);
        flvPlayer.load();


      }

    },
    play_live_ios:function (url) {
      let that = this

      that.video_cover_info = 0
      that.centeredVideo = 1
      that.voice = 1
      that.xiegang = 1
      // document.addEventListener('DOMContentLoaded', function () {
      // var videoElement = document.getElementById('videoElement');
      that.videoElement = this.$refs.videoElement;
      if (Hls.isSupported()) {
        that.hls = new Hls();

        // 监听 MANIFEST_LOADED 事件
        that.hls.on(Hls.Events.MANIFEST_LOADED, function (event, data) {
          // 获取最新的播放列表信息
          var variants = data.levels;
          // 选择最高分辨率的变种（你也可以根据其他策略选择变种）
          var highestVariant = variants[variants.length - 1];
          // 切换到选择的变种
          that.hls.startLevel = highestVariant.level;
        });
        // 加载 HLS 播放列表
        that.hls.loadSource(url);
        // 绑定到 video 元素
        that.hls.attachMedia(that.videoElement);

        // 监听Hls.js的错误事件
        this.hls.on(Hls.Events.ERROR, this.onHlsError);

        that.videoElement.muted = true;
        // 处理播放事件
        that.videoElement.addEventListener('play', function () {
          // 处理播放开始事件
          that.videoElement.muted = true;

          that.video_cover_info = 0
          that.centeredVideo = 1
          that.voice = 1
          that.xiegang = 1
        });

        // 处理暂停事件
        that.videoElement.addEventListener('pause', function () {
          // 处理暂停事件
        });
      }
      else if (document.getElementById('videoElement').canPlayType('application/vnd.apple.mpegurl')) {
        // 兼容 Safari

        that.videoElement.muted = true;
        that.videoElement.src =url ;
        that.videoElement.addEventListener('error', function () {
          console.error('Video playback failed');
          setTimeout(() => {
            that.videoElement.removeAttribute('src');
            that.videoElement.src = url;
          }, 4000);

        });
        console.log("兼容 Safari")
        that.video_cover_info = 0
        that.centeredVideo = 1
        that.voice = 1
        that.xiegang = 1
      }
      // });
    },
    play_live_other: function (url) {
      let that = this

      // 获取 video 元素
      // that.videoElement = document.getElementById('videoElement');
      that.videoElement = this.$refs.videoElement;
      // 创建 flv.js 实例
      that.flvPlayer = flvjs.createPlayer({
        type: 'flv',
        url: url
      });

      // 绑定 flv.js 实例到 video 元素
      that.flvPlayer.attachMediaElement(that.videoElement);
      // 加载 FLV 直播
      that.flvPlayer.load();
      // 监听FLV.js的错误事件
      that.flvPlayer.on(flvjs.Events.ERROR, that.onFlvError);

      that.video_cover_info = 0
      that.centeredVideo = 1
      that.voice = 1
      that.xiegang = 1
      // 监听播放事件
      that.videoElement.addEventListener('play', function () {
        // 处理播放开始事件
        that.videoElement.muted = true
        that.video_cover_info = 0
        that.centeredVideo = 1
        that.voice = 1
        that.xiegang = 1
      });

      that.videoElement.addEventListener('pause', function () {
        that.flvPlayer.load();
      });

    },
    onFlvError(errorType, detail) {
      console.error('FLV直播加载失败:', errorType, detail);
      // 在这里执行重新加载的操作
      if (this.flvPlayer) {
        this.flvPlayer.destroy();
        this.flvPlayer = null;
      }
      setTimeout(() => {
      }, 2000);
      setTimeout(() => {
        this.liveCountDown();
      }, 2000);
    },
    onHlsError(event, data) {
      console.error('HLS播放失败:', event, data);
      if (this.hls) {
        this.hls.destroy();
        this.hls = null;
      }
      setTimeout(() => {
        this.liveCountDown();
      }, 2000);
    },
    checkIfIOS() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone/.test(userAgent);
    },
    isTimeInRange() {
      // 获取当前时间
      const currentTime = new Date();

      // 设置目标时间范围（每天晚上九点半到九点三十八分）
      const targetStartTime = new Date();
      targetStartTime.setHours(22, 30, 0, 0);

      const targetEndTime = new Date();
      targetEndTime.setHours(23, 38, 0, 0);
      return currentTime >= targetStartTime && currentTime <= targetEndTime;
    },

    encrypt(text, key) {
      const cipherText = CryptoJS.AES.encrypt(text, key).toString();
      return cipherText;
    },
    decrypt(cipherText) {
      const bytes = CryptoJS.AES.decrypt(cipherText, "AHFED");
      return bytes.toString(CryptoJS.enc.Utf8);
    },


  }
}

</script>

<template>
  <div>
    <div class="tv-div">
      <div v-if="video_cover_info === 1" class="video_cover_info">澳門六合彩攪珠直播時間為每晚 <span
          style="color: #d80011">22:30-22:38</span>
        (北京時間)，敬請查看。
      </div>
      <video src ref="videoElement" id="videoElement" style="display:none;height: 184px;" v-show="centeredVideo === 1" class="centeredVideo" autoplay muted
             width="657"
             webkit-playsinline='true' playsinline='true' height="368">
        Your browser is too old which doesn't support HTML5 video.
      </video>
      <div class="voice" v-if="voice === 1"  @click="open_voice">
        <svg t="1683787859419" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
             p-id="2929" width="24" height="24">
          <path
              d="M585.8 127.5c-9.7-5.2-21.5-4.6-30.7 1.5L204.3 362.8H79c-8.3 0-15 6.7-15 15v268.5c0 8.3 6.7 15 15 15h125.3L555.2 895c5 3.3 10.8 5 16.6 5 4.9 0 9.7-1.2 14.1-3.6 9.7-5.2 15.8-15.3 15.8-26.3V153.8c-0.1-11-6.2-21.1-15.9-26.3z m-417.3 474h-29.7c-8.3 0-15-6.7-15-15v-149c0-8.3 6.7-15 15-15h29.7c8.3 0 15 6.7 15 15v149.1c0 8.2-6.7 14.9-15 14.9zM827.6 264c-13.7-9.2-32.3-5.5-41.4 8.1-9.2 13.7-5.5 32.2 8.1 41.4 66.4 44.5 106 118.7 106 198.4 0 79.5-39.4 153.5-105.4 198-13.7 9.2-17.3 27.7-8 41.4 5.8 8.5 15.2 13.1 24.8 13.1 5.7 0 11.6-1.6 16.7-5.1C910.8 703.8 960 611.3 960 512c0-99.7-49.5-192.4-132.4-248z"
              fill="#ffffff" p-id="2930"></path>
          <path
              d="M733.3 358.3c-13.7-9.2-32.2-5.5-41.4 8.1-9.2 13.7-5.5 32.2 8.1 41.4 34.8 23.3 55.6 62.3 55.6 104.1 0 41.7-20.6 80.5-55.3 103.9-13.7 9.2-17.3 27.7-8 41.4 5.8 8.6 15.2 13.2 24.8 13.2 5.7 0 11.6-1.6 16.7-5.1 51.1-34.4 81.6-91.8 81.6-153.3-0.1-61.8-30.7-119.2-82.1-153.7z"
              fill="#ffffff" p-id="2931"></path>
        </svg>
        <div class="xiegang" v-if="xiegang === 1"></div>
      </div>
    </div>
<!--    <div class="live_switch_area">-->
<!--      <div class="switch_live">直播線路切換:</div>-->
<!--      <div :class="live_line === '1' ? 'switch_btn btn_back':'switch_btn'" @click="switch_live_line(1)">線路一</div>-->
<!--      <div :class="live_line === '2' ? 'switch_btn btn_back':'switch_btn'" @click="switch_live_line(2)">線路二</div>-->

<!--    </div>-->
  </div>
</template>
<style scoped>
.live_switch_area{
  display: flex;
  align-items: center;
}

.switch_live{
  display: flex;
  align-items: center;
  width: 125px;
  height: 36px;
  justify-content: center;
  border-radius: 34px;
  font-size: 16px;
  cursor: pointer;
  color: #000000;
}
.switch_btn{
  display: flex;
  align-items: center;
  width: 100px;
  height: 27px;
  justify-content: center;
  border-radius: 34px;
  font-size: 14px;
  cursor: pointer;
  color: #000000;
  background: #ddd;
}
.btn_back {
  background-color: #d80011;
  color: #FFFFFF;
}
.video_cover img{
  width: 38px;
}
</style>
<style scoped src="../css/video_pc.css">
</style>